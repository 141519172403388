// react
import React from 'react';

export default function FooterNewsletter() {
    const socialLinks = [
        {
            key: 'facebook',
            url: 'https://www.facebook.com/festejospartyrentals/',
            iconClass: 'fab fa-facebook-f',
        },
        {
            key: 'instagram',
            url: 'https://www.instagram.com/festejos_party_rentals/?hl=en',
            iconClass: 'fab fa-instagram',
        },
    ];

    const socialLinksList = socialLinks.map((item) => (
        <li key={item.key} className={`footer-newsletter__social-link footer-newsletter__social-link--${item.key}`}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
                <i className={item.iconClass} />
            </a>
        </li>
    ));

    return (
        <div className="site-footer__widget footer-newsletter">
            <div className="footer-newsletter__text footer-newsletter__text--social">
                Follow us on social networks
            </div>

            <ul className="footer-newsletter__social-links">
                {socialLinksList}
            </ul>
        </div>
    );
}
