/* eslint-disable linebreak-style */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { setCurrentImagePreview } from '../../../store/product';

const SelectOption = ({
    innerProps, isDisabled, data,
}) => (!isDisabled ? (
    <div
        className="react-select__option"
        {...innerProps}
    >
        {data.label}
        <span
            className="react-select__color"
            style={{ backgroundColor: data.color }}
        />
    </div>
) : null);


SelectOption.propTypes = {
    isDisabled: PropTypes.bool,
    innerProps: PropTypes.shape().isRequired,
    data: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        color: PropTypes.string,
        image: PropTypes.string,
    }).isRequired,
};

SelectOption.defaultProps = {
    isDisabled: false,
};

class ColorSelect extends PureComponent {
  handleChange = (selectedOption) => {
      const { setCurrentImagePreview, onChange } = this.props;
      setCurrentImagePreview(selectedOption.image);
      onChange({ value: selectedOption });
  };

  render() {
      const { options, placeholder, value } = this.props;

      return (
          <Select
              value={value}
              onChange={this.handleChange}
              options={options}
              clearable={false}
              className="react-select"
              classNamePrefix="react-select"
              placeholder={placeholder}
              components={{ Option: SelectOption }}
          />
      );
  }
}

ColorSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        color: PropTypes.string,
        image: PropTypes.string,
    })),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

ColorSelect.defaultProps = {
    options: null,
    placeholder: 'Select...',
};

const mapDispatcherToProps = (dispatcher) => ({
    setCurrentImagePreview: (image) => dispatcher(setCurrentImagePreview(image)),
});

export default connect(null, mapDispatcherToProps)(ColorSelect);
