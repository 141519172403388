// react
import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

function FooterContacts({ contactUs }) {
    return (
        <div className="site-footer__widget footer-contacts">
            <h4 className="footer-contacts__title">Contact Us</h4>
            {
                ReactHtmlParser(contactUs)
            }
        </div>
    );
}

const mapStateToProps = (state) => ({ contactUs: state.content.contact_us });

export default connect(mapStateToProps)(FooterContacts);
