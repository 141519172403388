import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Spinner } from 'reactstrap';

const FieldValidators = {
    required: value => (value ? undefined : 'Required'),
    validName: value => (/^(\b[a-zA-Z0-9'-]+\b\s?){1,3}$/.test(value) ? undefined : 'Invalid Name'),
    validPhoneNumber: value => (/^\d{10}$/.test(value) ? undefined : 'Invalid Phonenumber'),
    email: value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
      'Invalid email address' : undefined,
};

const renderField = ({
    input,
    label,
    type,
    disabled,
    placeholder,
    meta: { touched, error, warning },
  }) => (
    <div>
      <span className="form__form-group-label">{label}</span>
      <div className="form__form-group-field">
        <input
          {...input}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          style={{ width: "100%" }}
        />
      </div>
      {touched
        && ((error && <span className="form__form-group-error">{error}</span>)
        || (warning && <span className="form__form-group-warning">{warning}</span>))}
    </div>
);

const UserInfoForm = ({ handleSubmit, submitButtonLabel, loading }) => {
    return (
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-12">
                <Field
                  name="fullName"
                  component={renderField}
                  type="text"
                  label="Your name:"
                  validate={[FieldValidators.required, FieldValidators.validName]}
                />
            </div>
            <div className="form-group col-md-12">
                <Field
                  name="phoneNumber"
                  component={renderField}
                  type="text"
                  label="Phone Number:"
                  normalize={(value, previousValue) => {
                    return (/^\d{0,10}$/.test(value) ? value : previousValue);
                  }}
                  validate={[FieldValidators.required, FieldValidators.validPhoneNumber]}
                /> 
            </div>
          </div>
          <div className="form-row">
          <div className="form-group col-md-12">
              <Field
                name="email"
                component={renderField}
                label="E-mail"
                type="email"
                placeholder="example@mail.com"
                validate={[FieldValidators.required, FieldValidators.email]}
              />
          </div>
          </div>
          <button
            className="btn btn-primary btn-xl btn-block cart__checkout-button"
            type="submit"
          >
            {
              (loading)
              ? <Spinner animation="border" />
              : <span>{submitButtonLabel}</span>
            }
          </button>
        </form>
    )
  };

export default reduxForm({
    form: 'user-information',
})(UserInfoForm);

