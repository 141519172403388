// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardImg,
    CardTitle,
} from 'reactstrap';

// third-party
import PropTypes from 'prop-types';

// application
import departmentsAria from '../../services/departmentsArea';
import StroykaSlick from '../shared/StroykaSlick';

import BlockHeader from '../shared/BlockHeader';

import withTracker from '../GATracking/withGATracker';


class BlockNewArrivalsCarousel extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { catalog, contentVersion, pageLinkTo } = this.props;

        const newArrivals = catalog.filter((item) => (item.isNew === true));
        const items = newArrivals.reverse().map((item, i) => (
            <Link
              key={i} to={`/${contentVersion}/product_page/${item.category}/${item.title}`}
              onClick={() => pageLinkTo('New Arrival Block', `${item.category}/${item.title}`)}
            >
                <Card style={{ paddingBottom: 0 }}>
                    <CardImg
                        top
                        src={item.image}
                        alt="New arrival item image"
                        style={{ width: 200, height: 'auto', alignSelf: 'center'}}
                    />
                    <CardBody style={{ paddingLeft: 5 }}>
                        <CardTitle
                            style={{
                            color: 'black',
                            textAlign: 'center',
                            textJustify: 'left',
                           }}
                        >
                            {item.title}
                        </CardTitle>
                    </CardBody>
                </Card>
            </Link>
        ));

        const slickSettings = {
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 10000,
            infinite: true,
            speed: 500,
            slidesToShow: (items.length > 5) ? 5 : items.length,
            slidesToScroll: (items.length > 5) ? 5 : items.length,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: (items.length > 5) ? 5 : items.length,
                        slidesToScroll: (items.length > 5) ? 5 : items.length,
                        dots: true,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: (items.length > 5) ? 3 : (items.length - 1) || 1,
                        slidesToScroll: (items.length > 5) ? 3 : (items.length - 1) || 1,
                        dots: true,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: (items.length > 5) ? 3 : (items.length - 1) || 1,
                        slidesToScroll: (items.length > 5) ? 3 : (items.length - 1) || 1,
                        dots: true,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false,
                        arrows: true,
                    },
                },
            ],
        };

        return (
            <div className="block-slideshow block block-slideshow--layout--with-departments">
                <div className="container">
                    <BlockHeader
                        title="New Arrivals"
                    />
                    <div className="row">
                        <div className="col-lg-12">
                            <StroykaSlick {...slickSettings}>
                                {items}
                            </StroykaSlick>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockNewArrivalsCarousel.propTypes = {
    withDepartments: PropTypes.bool,
};

BlockNewArrivalsCarousel.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => (
    {
        catalog: state.content.catalog_data,
        contentVersion: state.content.version,
    });

export default withTracker(connect(mapStateToProps)(BlockNewArrivalsCarousel), 'New Arrivals Carousel');
