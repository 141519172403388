// react
import React from 'react';
import { connect } from 'react-redux';

import ReactHtmlParser from 'react-html-parser';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import withGATracker from '../GATracking/withGATracker';

function SitePagePrivacy(props) {

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Website Privacy Policy`}</title>
            </Helmet>

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">Website Privacy Policy</h1>
                        </div>
                        <div className="document__content typography">
                            {
                                ReactHtmlParser(props.privacy)
                            }
                            <p>
                                For information about how to contact us, please visit our <Link to={`/${props.contentVersion}/site/contact-us`}> contact page.</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    privacy: state.content.privacy,
    contentVersion: state.content.version,
});

export default withGATracker(connect(mapStateToProps)(SitePagePrivacy), 'Website Privacy Policy');
