import {
    LOAD_PRODUCT,
    SET_CURRENT_IMAGE_PREVIEW,
    ADD_TO_BREADCRUMBS,
    RESET_BREADCRUMBS,
} from './productPageAction';

const initialState = {
    product: {
        colors: [],
        description: '',
        short_description: '',
        images: [],
        price: 0,
        related_items: [],
        title: '',
        current_image_preview: null,
        category: '',
    },
    breadcrumbs: [],
    flTaxRate: 0.065,
};

export default function (state = initialState, action) {
    switch (action.type) {
    case LOAD_PRODUCT:
        return {
            ...state,
            product: action.data.product,
        };
    case SET_CURRENT_IMAGE_PREVIEW:
        return {
            ...state,
            product: {
                ...state.product,
                current_image_preview: action.data,
            },
        };
    case ADD_TO_BREADCRUMBS: {
        const { data } = action;
        let updatedBreadcrumbs = state.breadcrumbs;
        if (updatedBreadcrumbs.length === 0) {
            updatedBreadcrumbs.push(data.category);
            updatedBreadcrumbs.push(data.product);
        };
        updatedBreadcrumbs.push(data.product);
        const foundIndex = updatedBreadcrumbs.findIndex(i => (i.title === data.product.title));
        updatedBreadcrumbs = updatedBreadcrumbs.slice(0, foundIndex + 1);
        return {
            ...state,
            breadcrumbs: updatedBreadcrumbs,
        };
    };
    case RESET_BREADCRUMBS:
        return {
            ...state,
            breadcrumbs: [],
        };
    default:
        return state;
    }
}
