export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const UPDATE_DELIVERY_INFO = 'UPDATE_DELIVERY_INFO';
export const RESET_CART = 'RESET_CART';
export const UPDATE_PICKUP_DATE = 'UPDATE_PICKUP_DATE';
export const UPDATE_DELIVERY_CHOICE = 'UPDATE_DELIVERY_CHOICE';
export const RESET_PICKUP_DATE = 'RESET_PICKUP_DATE';

export function addToCart(data) {
    return { type: ADD_TO_CART, data };
}

export function removeFromCart(data) {
    return { type: REMOVE_FROM_CART, data };
}

export function updateCartItem(data) {
    return { type: UPDATE_CART_ITEM, data };
}

export function updateDeliveryInfo(data) {
    return { type: UPDATE_DELIVERY_INFO, data };
}

export function updatePickupDate(data) {
    return { type: UPDATE_PICKUP_DATE, data };
}

export function updateDeliveryChoice(data) {
    return { type: UPDATE_DELIVERY_CHOICE, data };
}

export function resetPickupDate() {
    return { type: RESET_PICKUP_DATE };
}

export function resetCart(){
    return { type: RESET_CART };
}
