/* eslint-disable linebreak-style */
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setCurrentImagePreview } from '../../../store/product';

class ProductGallery extends PureComponent {
  changeImg = (i, e) => {
      const { setCurrentImagePreview } = this.props;
      e.preventDefault();
      setCurrentImagePreview(i.src);
  };

  render() {
      const { images, currentImagePreview } = this.props;
      return (
          <div className="product-gallery">
              <span className="product-gallery__current-img">
                  <img src={(currentImagePreview) || null} alt="product-img" />
              </span>
              <div className="product_gallery__gallery">
                  {images.map((img, i) => (
                      <button type="button" key={i} onClick={(e) => this.changeImg(img, e)} className="product-gallery__img-preview">
                          <img src={img.src} alt="product-img" />
                      </button>
                  ))}
              </div>
          </div>
      );
  }
}

ProductGallery.propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
        src: PropTypes.string,
    })).isRequired,
    currentImagePreview: PropTypes.shape({
        src: PropTypes.string,
    }).isRequired,
    setCurrentImagePreview: PropTypes.func,
};

const mapStateToProps = (state) => ({
    images: state.product.product.images,
    currentImagePreview: state.product.product.current_image_preview,
});

const mapDispatcherToProps = (dispatcher) => (
    {
        setCurrentImagePreview: (image) => dispatcher(setCurrentImagePreview(image)),
    }
);

export default connect(mapStateToProps, mapDispatcherToProps)(ProductGallery);
