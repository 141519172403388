// react
import React from 'react';
import { connect } from 'react-redux';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';

const Footer = ({ contentVersion }) => {
    const informationLinks = [
        { title: 'About Us', url: `/${contentVersion}/site/about-us` },
        { title: 'Services', url: `/${contentVersion}/site/service` },
        { title: 'Delivery Information', url: `/${contentVersion}/site/delivery` },
        { title: 'FAQ', url: `/${contentVersion}/site/faq` },
        { title: 'Careers', url: `/${contentVersion}/site/careers` },
        { title: 'Contact Us', url: `/${contentVersion}/site/contact-us` },
        { title: 'Terms and Conditions', url: `/${contentVersion}/site/terms` },
        { title: 'Website Privacy Policy', url: `/${contentVersion}/site/privacy` },
    ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col-6 col-md-6 col-lg-4">
                            <FooterLinks title="Information" items={informationLinks} />
                        </div>
                        <div className="col-6 col-md-6 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        <div>
                          Powered by
                          {' '}
                          <a href="https://festejosrs.com/" rel="noopener noreferrer" target="_blank">FestejosRS</a>
                          {' '}
                        </div>
                        <div>
                          <span>
                              This site is protected by reCAPTCHA and the Google
                              <a href='https://policies.google.com/privacy'> Privacy Policy</a> and <a href='https://policies.google.com/terms'>Terms of Service</a> apply.
                          </span>
                        </div>
                    </div>
                    <div className="site-footer__payments">
                        <img src="images/payments.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ contentVersion: state.content.version });

export default connect(mapStateToProps)(Footer);
