export default function mobileMenu(version, categories) {
    const contentVersion = (version) ? `/${version}` : '';
    const catalogCategories = categories.map((c) => ({
        type: 'link',
        label: c.category,
        url: `/${version}/catalog/${c.category}`,
        image: c.image
    }));
    return [
        {
            type: 'link',
            label: 'Home',
            url: `${contentVersion}`,
        },
        {
            type: 'link',
            label: 'Catalog',
            children: catalogCategories,
        },
        {
            type: 'link',
            label: 'Inspirations',
            url: `${contentVersion}/inspirations`,
        },
        {
            type: 'link',
            label: 'About Us',
            url: `${contentVersion}/site/about-us`,
        },
        {
            type: 'link',
            label: 'Service',
            url: `${contentVersion}/site/service`,
        },
        {
            type: 'link',
            label: 'Contact Us',
            url: `${contentVersion}/site/contact-us`,
        },
    ];
}
