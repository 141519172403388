// react
import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

import withGATracker from '../GATracking/withGATracker';

function SitePageFaq(props) {
    return (
        <React.Fragment>
            <Helmet>
                <title>{`FAQ`}</title>
            </Helmet>

            <PageHeader header="Frequently Asked Questions" />

            <div className="block faq">
                <div className="container">
                    <div className="faq__section">
                      { ReactHtmlParser(props.faq) } 
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    faq: state.content.faq,
});

export default withGATracker(connect(mapStateToProps)(SitePageFaq), 'FAQ');
