import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const VirtualAppointmentButton = (props) => (
    <Link
      to={`/${props.contentVersion}/site/contact-us`}
      style={{ textDecoration: "none" }}>
      <span
        className="lnr lnr-calendar-full"
        style={{
          backgroundColor: "#70BBFD ",
          color: "white",
          textAlign: "center",
          padding: 5}}>
            <span style={{ margin: 10 }}>VIRTUAL APPOINTMENT</span>
      </span>
    </Link>
);

const mapStateToProps = (state) => ({ contentVersion: state.content.version });

export default connect(mapStateToProps)(VirtualAppointmentButton);