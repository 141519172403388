// react
import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import withGATracker from '../GATracking/withGATracker';

function SitePageTerms(props) {

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Terms And Conditions`}</title>
            </Helmet>

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">Terms And Conditions</h1>
                        </div>
                        <div className="document__content typography">
                            {
                                ReactHtmlParser(props.terms)
                            }
                            <p>
                                For information about how to contact us, please visit our
                                <Link to={`/${props.contentVersion}/site/contact-us`}>contact page</Link>
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    terms: state.content.terms,
    contentVersion: state.content.version,
});

export default withGATracker(connect(mapStateToProps)(SitePageTerms), 'Terms and Conditions');
