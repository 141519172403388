// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import Currency from '../shared/Currency';

import withGATracker from '../GATracking/withGATracker';

function Suggestions(props) {
    const {
        context,
        className,
        products,
        contentVersion,
        pageLinkTo,
    } = props;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);
    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">
                {
                    (products && products.map((product, index) => (
                        <li key={index} className="suggestions__item">
                            <div className="suggestions__item-image">
                                <img src={product.image} alt="" />
                            </div>
                            <div className="suggestions__item-info">
                                <Link
                                  className="suggestions__item-name"
                                  to={`/${contentVersion}/product_page/${product.category}/${product.title}`}
                                  onClick={() => pageLinkTo('Search Suggestion', `${product.category}/${product.title}`)} 
                                >
                                    {product.title}
                                </Link>
                            </div>
                            <div className="suggestions__item-price">
                                <Currency value={product.price} />
                            </div>
                        </li>
                    )))
                }
            </ul>
        </div>
    );
}

const mapStateToProps = (state) => ({
    contentVersion: state.content.version,
});

export default withGATracker(connect(mapStateToProps)(Suggestions), 'Product Search Suggestion');
