import React, { PureComponent } from 'react';
import { Collapse } from 'reactstrap';
import { Icon } from '@iconify/react';
import tagIcon from '@iconify/icons-oi/tag';
import PropTypes from 'prop-types';

class MenuCollapse extends PureComponent {
    constructor() {
        super();
        this.state = {
            collapse: false,
            status: 'closed',
            icon: <Icon icon={tagIcon} color="white" width="25" height="25" />,
        };
    }

  onEntering = () => {
      this.setState({ status: 'opening', icon: <Icon icon={tagIcon} color="white" width="25" height="25" /> });
  };

  onEntered = () => {
      this.setState({ status: 'opened', icon: <Icon icon={tagIcon} color="white" width="25" height="25" /> });
  };

  onExiting = () => {
      this.setState({ status: 'closing', icon: <Icon icon={tagIcon} color="white" width="25" height="25" /> });
  };

  onExited = () => {
      this.setState({ status: 'closed', icon: <Icon icon={tagIcon} color="white" width="25" height="25" /> });
  };

  toggle = () => {
      this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  unCollapse = () => this.setState({ collapse: false });

  render() {
      const { className, children } = this.props;
      const { icon, collapse, status } = this.state;

      return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
          <div
              className={`collapse__wrapper ${status} ${className}`}
              dir="ltr"
              style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
              }}
              onMouseLeave={this.unCollapse}
              onMouseEnter={this.toggle}
          >
              <div style={{
                  width: 40,
                  height: 40,
                  backgroundColor: 'rgba(34,25,36,.3)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
              }}>
                  {icon}
              </div>
              <Collapse
                  isOpen={collapse}
                  onEntering={this.onEntering}
                  onEntered={this.onEntered}
                  onExiting={this.onExiting}
                  onExited={this.onExited}
              >
                  {children}
              </Collapse>
          </div>
      );
  }
}

MenuCollapse.propTypes = {
    className: PropTypes.string,
    children: PropTypes.element.isRequired,
};

MenuCollapse.defaultProps = {
    className: '',
};

export default MenuCollapse;
