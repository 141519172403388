/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import cityOptions from './CityOptions';
import { updateDeliveryInfo } from '../../../store/cart';
import withGATracker from '../../GATracking/withGATracker';

const { REACT_APP_FESTEJOSWEB_BACKEND_URL } = process.env

//If date is not within acceptable business day, return next acceptable business day
// - Delivery should be scheduled at least one business day in advance
// - Sundays are non working days
function checkStartingDate(startDate){
    startDate.setDate(startDate.getDate() + 2);
    startDate.setDate((startDate.getDay() === 0) ? startDate.getDate() + 2: startDate.getDate());
    return startDate;
}

//Sundays is a non working day
//Delivery should be scheduled at least one business day in advance
function filterDays(date){
    const currentDate = new Date();
    const nextDate = new Date();
    nextDate.setDate(currentDate.getDate() + 1);
    const dateValue = date.getFullYear().toString() +
                      date.getMonth().toString() +
                      date.getDate().toString();
    const currentDateValue = currentDate.getFullYear().toString() +
                             currentDate.getMonth().toString() +
                             currentDate.getDate().toString(); 
    const nextDateValue = nextDate.getFullYear().toString() +
                          nextDate.getMonth().toString() +
                          nextDate.getDate(); 
    
    return (
        (date.getDay() !== 0) && 
        (dateValue !== currentDateValue) && 
        (dateValue !== nextDateValue));
}
class DeliveryCalculator extends PureComponent {
    constructor(props) {
        super(props);
        const startingDate = checkStartingDate(new Date());
        this.state = {
            startDate: startingDate, 
            endDate: startingDate,
            address: {
                address: '',
                city: '',
            },
            startDateDisplayError: null,
            endDateDisplayError: null,
            addressDisplayError: null,
            cityDisplayError: null,
            deliveryCost: null,
            calculating: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.calculateDelivery = this.calculateDelivery.bind(this);
        this.renderDeliveryCostReasons = this.renderDeliveryCostReasons.bind(this);
    }

    componentDidMount() {
        const { deliveryCost, deliveryInfo } = this.props;
        this.setState(
            {
                deliveryCost,
                address: deliveryInfo.address,
            },
        );
    }

  handleChangeStart = (changedStartDate) => {
      const { endDate } = this.state;
      let newEndDate = endDate;
      let newStartDate = changedStartDate;
      if (changedStartDate <= new Date()) {
          newStartDate = checkStartingDate(new Date());
      }
      if (newStartDate > newEndDate) {
          newEndDate = newStartDate;
      }

      this.setState({ startDateDisplayError: null, startDate: newStartDate, endDate: newEndDate });
  }

  handleChangeEnd = (changedEndDate) => {
      const { startDate } = this.state;
      let newEndDate = changedEndDate;
      if (changedEndDate < startDate) {
          newEndDate = startDate;
      }
      this.setState({ endDateDisplayError: null, endDate: newEndDate });
  }

  handleAddressChange = (addressChange) => {
      const { address } = this.state;
      if (addressChange) {
          this.setState({
              addressDisplayError: null,
              address:
              {
                  ...address,
                  address: (addressChange) ? addressChange.currentTarget.value : null,
              },
          });
      }
  }

  handleCityChange = (cityChange) => {
      const { address } = this.state;
      if (cityChange) {
          this.setState({ cityDisplayError: null, address: { ...address, city: cityChange } });
      }
  }

  handleChange({
      changedStartDate, changedEndDate, addressChange, cityChange,
  }) {
      const { startDate, endDate, address } = this.state;

      const newStartDate = changedStartDate || startDate;
      const newEndDate = changedEndDate || endDate;
      const newAddress = (
          (addressChange)
              ? addressChange.currentTarget.value
              : null) || address.address;
      const newCity = cityChange || address.city;


      this.setState({
          startDate: newStartDate,
          endDate: newEndDate,
          address: { address: newAddress, city: newCity },
      });
  }

  async calculateDelivery() {
      const { startDate, endDate, address } = this.state;
      const { onDeliveryCostUpdate, chairsInCart, googleReCaptchaProps, pageGAEvent  } = this.props;

      pageGAEvent('Calculate Delivery', 'Calculate Delivery');

      const token = await googleReCaptchaProps.executeRecaptcha('calculate_delivery');

      if (this.isValidFields(startDate, endDate, address)) {
          const delivery = Math.trunc(startDate.getTime() / 1000);
          const pickup = Math.trunc(endDate.getTime() / 1000);
          const destination = `${address.address}, ${address.city.value}`;

          this.setState({ calculating: true });
          fetch(`${REACT_APP_FESTEJOSWEB_BACKEND_URL}deliverycalculator?destination=${destination}&delivery=${delivery}&pickup=${pickup}&chairs_count=${chairsInCart}`,{
              method: 'GET',
              headers: {
                  Authorization: token,
              },
          })
              .then((response) => response.json())
              .then((dta) => {
                  onDeliveryCostUpdate({
                      deliveryCost: dta,
                      deliveryInfo: {
                          startDate,
                          endDate,
                          address,
                      },
                  });
                  this.setState({ deliveryCost: dta, calculating: false });
              });
      }
  }

  isValidFields(startDate, endDate, address) {
      let valid = true;
      if (!startDate) {
          this.setState({ startDateDisplayError: 'Delivery Date and Time required' });
          valid = false;
      }
      if (!endDate) {
          this.setState({ endDateDisplayError: 'Pickup Date and Time required' });
          valid = false;
      }
      if (!address.address) {
          this.setState({ addressDisplayError: 'Address is required' });
          valid = false;
      }
      if (!address.city) {
          this.setState({ cityDisplayError: 'City is required' });
          valid = false;
      }
      return valid;
  }

  renderDeliveryCostReasons() {
      const { deliveryCost } = this.state;
      if (deliveryCost) {
          const { delivery, pickup } = deliveryCost;
          const deliveryMessages = [];
          const pickupMessages = [];
          if (delivery.isAfterHours.value) {
              deliveryMessages.push(`Delivery is after 6 pm which added $${delivery.isAfterHours.cost} to delivery charges`);
          }
          if (delivery.isHoliday.value) {
              deliveryMessages.push(`Delivery is on a holiday which added $${delivery.isHoliday.cost} to delivery charges`);
          }
          if (pickup.isAfterHours.value) {
              pickupMessages.push(`Pickup is after 6 pm which added $${pickup.isAfterHours.cost} to pickup charges`);
          }
          if (pickup.isHoliday.value) {
              pickupMessages.push(`Pickup is on a holiday which added $${pickup.isHoliday.cost} to pickup charges`);
          }
          const deliveryResponse = deliveryMessages.map((d, i) => (<li key={i}>{d}</li>));
          const pickupResponse = pickupMessages.map((p, i) => (<li key={i}>{p}</li>));

          if (deliveryResponse.length || pickupResponse.length) {
              return (
                  <div style={{ textAlign: 'left', width: 350 }}>
                      <span>
                  Please note:
                      </span>
                      <ul>{deliveryResponse}</ul>
                      <ul>{pickupResponse}</ul>
                  </div>
              );
          }
      }
      return null;
  }

  render() {
      const {
          startDate,
          endDate,
          address,
          startDateDisplayError,
          endDateDisplayError,
          addressDisplayError,
          cityDisplayError,
          calculating,
      } = this.state;

      const smallDevice = window.matchMedia('(max-width: 440px)').matches;
      return (
          <div className="col-lg-12 col-md-12">
              <div className="form form__form-group">
                  {
                      (addressDisplayError)
                          ? <span className="form__form-group-label" style={{ color: 'red' }}>{addressDisplayError}</span>
                          : <span className="form__form-group-label">Address</span>
                  }
                  <input
                    type="text"
                    name="address"
                    value={address.address}
                    className="form-control"
                    onChange={this.handleAddressChange} />
                  {
                      (cityDisplayError)
                          ? <span className="form__form-group-label" style={{ color: 'red', marginTop: 10 }}>{cityDisplayError}</span>
                          : <span className="form__form-group-label" style={{ marginTop: 10 }}>City (Florida only)</span>
                  }
                  <Select
                      name="city"
                      value={address.city}
                      onChange={this.handleCityChange}
                      options={cityOptions.map((o) => ({ value: o, label: o }))}
                      clearable={false}
                      className="react-select"
                      placeholder="City"
                      classNamePrefix="react-select"
                  />
              </div>
              <div className="form form__form-group">
                       {
                        (startDateDisplayError)
                            ? <label style={{ color: 'red' }}>{startDateDisplayError}</label>
                            : <label>Delivery Date and Time:</label>
                       }
                      <div className="date-picker" style={{ marginBotton: 10 }}>
                          <DatePicker
                              selected={startDate}
                              startDate={startDate}
                              filterDate={filterDays}
                              endDate={endDate}
                              onChange={this.handleChangeStart}
                              showTimeSelect
                              dateFormat="Pp"
                              dropDownMode="select"
                              withPortal={smallDevice}
                          />
                      </div>
                      {
                        (endDateDisplayError)
                            ? <label style={{ color: 'red' }}>{endDateDisplayError}</label>
                            : <label style={{ marginTop: 15 }}>Pickup Date and Time:</label>
                      }
                      <div className="date-picker" style={{ marginBotton: 10 }}>
                          <DatePicker
                              selected={endDate}
                              startDate={startDate}
                              filterDate={filterDays}
                              endDate={endDate}
                              onChange={this.handleChangeEnd}
                              showTimeSelect
                              dateFormat="Pp"
                              dropDownMode="select"
                              withPortal={smallDevice}
                          />
                      </div>
              </div>
              <Button
                color="primary"
                className="btn btn-dark btn-sm btn-svg-icon"j
                onClick={() => this.calculateDelivery()}
              >
                  {
                      (calculating)
                      ? <Spinner animation="border" />
                      : <span>Calculate Delivery</span>
                  }
              </Button>
              {
                this.renderDeliveryCostReasons()
              }
          </div>
      );
  }
}

DeliveryCalculator.propTypes = {
    onDeliveryCostUpdate: PropTypes.func.isRequired,
};


const mapDispatcherToProps = (dispatcher) => (
    {
        onDeliveryCostUpdate: ((delivery) => dispatcher(updateDeliveryInfo(delivery))),
    });
const mapStateToProps = (state) => (
    {
        chairsInCart: state.cart.chairsInCart,
        deliveryInfo: state.cart.deliveryInfo,
        deliveryCost: state.cart.deliveryCost,
    });

export default withGATracker(withGoogleReCaptcha(connect(mapStateToProps, mapDispatcherToProps)(DeliveryCalculator)), 'Delivery Calculator');
