import React from 'react';
import UserInfoFormCard from './components/UserInfoFormCard';

const UserInfoVerification = ({onSubmit, verifyBeforeSending, eventually, succesMessage, submitButtonLabel}) => (
  <UserInfoFormCard
    onSubmit={onSubmit}
    verifyBeforeSending={verifyBeforeSending}
    succesMessage={succesMessage}
    submitButtonLabel={submitButtonLabel}
    eventually={eventually}
  />
);

export default UserInfoVerification;
