// react
import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import PageHeader from '../shared/PageHeader';

import withGATracker from '../GATracking/withGATracker';

function SitePageCareers(props) {

    return (
        <React.Fragment>
            <Helmet>
                <title>Careers</title>
            </Helmet>

            <PageHeader header="Be part of the team" />

            <div className="block">
                <div className="container">
                            {
                                ReactHtmlParser(props.careers)
                            }
                            <p>
                                For information about how to contact us, please visit our
                                <Link to={`/${props.contentVersion}/site/contact-us`}> contact page</Link>
                                .
                            </p>
                </div>
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    careers: state.content.careers,
    contentVersion: state.content.version,
});

export default withGATracker(connect(mapStateToProps)(SitePageCareers), 'Careers');
