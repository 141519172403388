/* eslint-disable react/no-array-index-key */
import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from 'reactstrap';
import UserInfoForm from './UserInfoForm';

import withGATracker from '../../GATracking/withGATracker';

const valid_code = value => (/^\d{6}$/.test(value) ? true : false);

class EmailQuoteFormModal extends React.Component {
  constructor(){
    super();
    this.state = {
      verificationCode: '',
      componentToRender: () => null, 
      errorMessage: {
        field: null,
        message: null,
      },
      loading: false,
      submitted: false,
    };
  }

  componentDidMount() {
    const { fullName, email, phoneNumber } = this.props;
    this.setState({ componentToRender: () => this.renderCodeVerificationForm(fullName, email, phoneNumber) });
  }

  onCodeChange = (c) => {
    const { value } = c.currentTarget;
    const { errorMessage } = this.state;
    if (/^\d*$/.test(value)){
      if (errorMessage.field === 'verificationCode') {
        this.setState({ errorMessage: { field: null, message: null }});
      }  
      this.setState({ verificationCode: value });
    }
  }

  reRenderUserInfo = () => {
    this.setState({
      componentToRender: () => this.renderUserInfoForm(),
      errorMessage: {
        field: null,
        message: null,
      },
     });
  }

  onCloseModal = () => {
    const { toggle } = this.props;
    this.setState({
      verificationCode: '',
      errorMessage: {
        field: null,
        message: null,
    }});
    toggle();
  }

  onSend = (d, name, mail, phone) => {
    d.preventDefault();
    const {
      onSubmit,
      fullName,
      email,
      phoneNumber,
    } = this.props;
    const { verificationCode } = this.state;
    if (!valid_code(verificationCode)){
      this.setState({ errorMessage: { field: 'verificationCode', message: 'Invalid Code'} })
      return;
    }

    this.setState({ loading: true });
    onSubmit({
      verificationCode,
      fullName: name || fullName,
      email: mail || email,
      phoneNumber: phone || phoneNumber,
    })
    .then(() => {
      this.setState({
        componentToRender: () => this.renderConfirmationMessage(),
        errorMessage: { field: null, message: null },
        loading: false,
      });
    })
    .catch(error => {
      this.setState({ errorMessage: { field: 'verificationCode', message: error.toString() }, loading: false });
    });
  } 

  onResubmitVerificationRequest = () => {
    const {
      resubmitVerificationRequest,
      fullName,
      email,
      phoneNumber,
      pageGAEvent,
     } = this.props;
     this.setState({ loading: true });
    resubmitVerificationRequest({ fullName, email, phoneNumber })
    .then(() => this.setState(
      { componentToRender: () => this.renderCodeVerificationForm(fullName, email, phoneNumber),
        loading: false,
        verificationCode: '' }));

    pageGAEvent('Resend Email Verification Code', 'Resend Email Verification Code');
  }

  renderConfirmationMessage = () => {
    const { errorMessage } = this.state;
    const { succesMessage, eventually } = this.props;
    const toggle = () => {
      eventually();
      this.onCloseModal();
    };

    return (
      <div>
        <ModalHeader toggle={toggle}
        >
          <h3>Thank You!</h3>
          <span style={{ color: 'red' }}>{errorMessage.message}</span>
        </ModalHeader>
        <ModalBody style={{ margin: 5 }}>
          <div className="row">
            <p>{succesMessage}</p>
          </div>
          <div className="row"
            style={{ alignItems: 'center', justifyContent: 'center', marginTop: 5 }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={toggle}
            >
              Ok
            </button>
          </div>
        </ModalBody>
        
      </div>
    );
  };

  renderUserInfoForm = () => {
    const { errorMessage, loading } = this.state;
    const { submitButtonLabel } = this.props;
    return (
      <div>
        <ModalHeader toggle={this.onCloseModal}>
          <h3>Your information</h3>
          <span style={{ color: 'red' }}>{errorMessage.message}</span>
        </ModalHeader>
        <ModalBody>
          <UserInfoForm 
            onSubmit={this.onResubmitVerificationRequest}
            submitButtonLabel={submitButtonLabel}
            loading={loading}
          />
        </ModalBody>
      </div>
  );
    }

  renderCodeVerificationForm = (fullName, email, phoneNumber) => {
    const { verificationCode, errorMessage, loading } = this.state;
    const { pageGAEvent } = this.props;
    pageGAEvent('Render Verification Code Form', 'Render Verification Code Form');
    return (
      <div>
        <ModalHeader toggle={this.onCloseModal}>
          <h3>Email Verification Code</h3>
          <span style={{ color: 'red' }}>{errorMessage.message}</span>
        </ModalHeader>
        <ModalBody>
          <div className="col-12 col-lg-12">
            <form onSubmit={(e) => this.onSend(e, fullName, email, phoneNumber)}>
              <div className="form-row">
                <div className="form-group col-12">
                  <label htmlFor="form-verificationcode">Enter verification code sent to provided email</label>
                  <input
                    name="verificationCode"
                    value={verificationCode}
                    type="text"
                    id="form-verificationcode"
                    className="form-control"
                    onChange={this.onCodeChange}
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block cart__checkout-button">
                {
                  (loading)
                  ? <Spinner animation="border" />
                  : <span>Submit</span>
                }
              </button>
            </form>
            <button type="button" className="btn btn-secondary" onClick={() => this.reRenderUserInfo()}>Resend Verification Code</button>
          </div>
        </ModalBody>
      </div>
    )
  }

  render() {
    const { componentToRender } = this.state;
    const { isOpen } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={this.onCloseModal}
        centered={true}
        draggable={true}
      >
        {componentToRender()} 
      </Modal>
    );
  }
}

const selector = formValueSelector('user-information');
const mapStateToProps = (state) => ({
  contentVersion: state.content.version,
  fullName: selector(state, 'fullName'),
  email: selector(state, 'email'),
  phoneNumber: selector(state, 'phoneNumber'),
});


export default withGATracker(connect(mapStateToProps)(EmailQuoteFormModal), 'Email Verification Code Modal');
