import React from 'react';
import { PropTypes } from 'prop-types';
import { Spinner } from 'reactstrap';

const CenterLoadingSpinner = ({ loading, children }) => (
    (loading)
    ? (
        <div className="center-loading-spinner">
          <Spinner animation="border" variant="success" />
        </div>
      )
     : children
);

CenterLoadingSpinner.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.objectOf(Object).isRequired,
};

export default CenterLoadingSpinner;
