/* eslint-disable react/no-multi-comp */
/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';
import { FullWideNotification } from './Notification';

function ColorStatesFullWidth(props) {
  const { color, message } = props;
  return (
    <FullWideNotification
      color={color}
      message={message}
    />
  );
}

ColorStatesFullWidth.propTypes = {
  color: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default ColorStatesFullWidth;
