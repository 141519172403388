// react
import React, { Component } from 'react';
import { connect } from 'react-redux';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import departmentsAria from '../../services/departmentsArea';
import StroykaSlick from '../shared/StroykaSlick';

import BlockHeader from '../shared/BlockHeader';

import InspirationImage from '../Inspiration/InspirationImage';

const slickSettings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                centerPadding: 100,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};
class BlockRelatedProductInspirationsCarousel extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { withDepartments, inspirations, product } = this.props;
        const related_inspirations = inspirations.filter(
            inspiration => (
                inspiration.items.find(item => ((product.category.trim() === item.category.trim()) && (product.title.trim() === item.title.trim())))
            )
        );
        const blockClasses = classNames(
            'block-slideshow block',
            {
                'block-slideshow--layout--full': !withDepartments,
                'block-slideshow--layout--with-departments': withDepartments,
            },
        );

        const layoutClasses = classNames(
            'col-12',
            {
                'col-lg-12': !withDepartments,
                'col-lg-9': withDepartments,
            },
        );

        return (
            <div className={blockClasses} style={{ margintTop: -70 }}>
                <div className="container">
                    {
                      (related_inspirations.length)
                      ? <BlockHeader
                          title="Looks with this item"
                        />
                      : null
                    }
                    <div className="row">
                        {withDepartments && (
                            <div className="col-6 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                        )}
                        <div className={layoutClasses}>
                            <div className="block-inspirations-slideshow__body">
                                <StroykaSlick {...slickSettings}>
                                    {
                                        related_inspirations.map((inspiration) => (
                                            <div>
                                                <InspirationImage
                                                    image={inspiration.image}
                                                    title={inspiration.title}
                                                    featuredItems={inspiration.items}
                                                />
                                            </div>
                                        ))
                                    }
                                </StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockRelatedProductInspirationsCarousel.propTypes = {
    withDepartments: PropTypes.bool,
};

BlockRelatedProductInspirationsCarousel.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => (
    {
        inspirations: state.content.inspiration,
        product: state.product.product,
    });

export default connect(mapStateToProps)(BlockRelatedProductInspirationsCarousel);
