// react
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';

import { Link } from 'react-router-dom';

import PageHeader from '../shared/PageHeader';

import withGATracker from '../GATracking/withGATracker';

function SitePageAboutUs({ aboutUs, contentVersion }) {
    return (
        <React.Fragment>
            <Helmet>
                <title>{`About Us`}</title>
            </Helmet>

            <PageHeader header="About Us" />

            <div className="block">
                <div className="container">
                                {
                                    ReactHtmlParser(aboutUs)
                                }
                                <p>
                                  For information about how to contact us, please visit our
                                  <Link to={`/${contentVersion}/site/contact-us`}> contact page</Link>
                                .
                               </p>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => (
    {
        aboutUs: state.content.about_us,
        contentVersion: state.content.version,
    }
);

export default withGATracker(connect(mapStateToProps)(SitePageAboutUs), 'About Us');
