import {
    LOAD_INSPIRATION,
} from './inspirationPageAction';

const initialState = {
    inspiration: {
        title: '',
        description: '',
        items: [],
        image: null,
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
    case LOAD_INSPIRATION:
        return {
            ...state,
            inspiration: action.data,
        };
    default:
        return state;
    }
}
