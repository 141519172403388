/* eslint-disable linebreak-style */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-return-assign */
import React from 'react';
import NotificationSystem from 'rc-notification';
import BasicNotifications from './components/BasicNotifications';
import ColorStates from './components/ColorStates';
import ImageNotifications from './components/ImageNotifications';
import ColorStatesFullWidth from './components/ColorStatesFullWidth';


let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
    switch (position) {
    case 'left-up':
        notificationLU.notice({
            content: notification,
            duration: 5,
            closable: true,
            style: { top: 0, left: 0 },
            className: position,
        });
        break;
    case 'right-up':
        notificationRU.notice({
            content: notification,
            duration: 5,
            closable: true,
            style: { top: 0, left: 'calc(100vw - 100%)' },
            className: position,
        });
        break;
    default:
        notificationTC.notice({
            content: notification,
            duration: 5,
            closable: true,
            style: { top: 0, left: 0 },
            className: position,
        });
        break;
    }
};

const setNotificationSystem = () => {
    NotificationSystem.newInstance({}, (n) => notificationLU = n);
    NotificationSystem.newInstance({}, (n) => notificationRU = n);
    NotificationSystem.newInstance({}, (n) => notificationTC = n);
};

// eslint-disable-next-line no-unused-vars
const destroyNotificationSystem = () => {
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
};

const show = (notificationComponent, position) => {
    setNotificationSystem();
    return (
        showNotification({
            notification: notificationComponent,
            position,
        })
    );
};

const BasicNotification = (position, title, message) => show(<BasicNotifications
    position={position}
    title={title}
    message={message}
/>,
position);

const ColorState = (color, title, message) => show(<ColorStates
    color={color}
    title={title}
    message={message}
/>,
'right-up');

const ColorStateFullWidth = (color, message) => show(<ColorStatesFullWidth
    color={color}
    message={message}
/>,
'full');

const ImageNotification = (position, title, image, message) => show(<ImageNotifications
    title={title}
    image={image}
    message={message}
/>,
position);

export {
    BasicNotification,
    ColorState,
    ColorStateFullWidth,
    ImageNotification,
};
