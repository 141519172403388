// react
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slick from 'react-slick';

// application
import departmentsAria from '../../services/departmentsArea';
import PresentationImage from '../PresentationImage/PresentationImage';


class BlockSlideShow extends Component {
    slickSettings = {
        autoplay: true,
        autoplaySpeed: 5000,
        swipeOnHover: true,
        fade: true,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => this.setState({ activeSlide: current }),
    };

    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    constructor() {
        super();
        this.state = {
            activeSlide: 0,
        };
    }

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    onShopClick = () => {
        const { presentation, contentVersion, history } = this.props;
        const { activeSlide } = this.state;
        history.push(`/${contentVersion}/catalog/${presentation[activeSlide].description}`);
    }

    render() {
        const { withDepartments, contentVersion } = this.props;

        const blockClasses = classNames(
            'block-slideshow block',
            {
                'block-slideshow--layout--full': !withDepartments,
                'block-slideshow--layout--with-departments': withDepartments,
            },
        );

        const layoutClasses = classNames(
            'col-12',
            {
                'col-lg-12': !withDepartments,
                'col-lg-9': withDepartments,
            },
        );

        const { presentation } = this.props;
        const slides = presentation.map((slide, index) => {
            const { image, description } = slide;
            return (
                <PresentationImage
                    key={index}
                    image={image}
                    description={description}
                    contentVersion={contentVersion}
                    onShopClick={this.onShopClick}
                />
            );
        });

        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        {withDepartments && (
                            <div className="col-6 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                        )}

                        <div className={`${layoutClasses} block-presentation`}>
                            <div className="block-slideshow__body">
                                <Slick {...this.slickSettings}>
                                    {slides}
                                </Slick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    presentation: state.content.presentation,
    contentVersion: state.content.version,
});

export default withRouter(connect(mapStateToProps)(BlockSlideShow));
