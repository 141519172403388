// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardImg,
    CardTitle,
} from 'reactstrap';

// third-party
import PropTypes from 'prop-types';

// application
import departmentsAria from '../../../services/departmentsArea';
import StroykaSlick from '../../shared/StroykaSlick';

import BlockHeader from '../../shared/BlockHeader';

import withGATracker from '../../GATracking/withGATracker';


class OtherItemsToConsiderCarousel extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const {
            inspiration: { items },
            catalog,
            contentVersion,
            pageLinkTo,
         } = this.props;
        const featureCatalogItems = (items)
        ? items.map(item => catalog.find(c => (
            (c.title.trim() === item.title.trim()) && (c.category.trim() === item.category.trim())
            )))
        : [];

        let otherItemsToConsider = [];
        if (featureCatalogItems.length) {
          featureCatalogItems.forEach(i => i.related_items.forEach(ri => otherItemsToConsider.push(ri)));
        }

        otherItemsToConsider = Array.from(new Set(otherItemsToConsider));
        const otherItemsToConsiderCatalog = (otherItemsToConsider.length)
          ? otherItemsToConsider.map((ic) => (
              catalog.find((c)=> (
                  (c.category.trim() === ic.category.trim()) && (c.title.trim() === ic.title.trim())
                  )
                )
              )
            )
          : [];

        const catalogItems = otherItemsToConsiderCatalog.map((item, i) => (
            <Link
              key={i}
              to={`/${contentVersion}/product_page/${item.category}/${item.title}`}
              onClick={() => pageLinkTo('Other Items To Consider', `${item.category}/${item.title}`)}
            >
                <Card>
                    <div style={{ width: 150, maxHeight: 150 }}>
                      <CardImg
                        top
                        src={item.image}
                        alt="Related item image"
                        style={{ width: 150, height: 150, alignSelf: 'center'}}
                      />
                    </div>
                    <CardBody style={{ paddingLeft: 5 }}>
                        <CardTitle
                          style={{ textAlign: 'left' }}
                        >
                            <div className="row">
                                  <div className="col-12 text-left">
                                    <h4 className="carousel-catalog-item__title">{item.title}</h4>
                                  </div>
                            </div>
                            <div className="row">
                              <div className="col-12 text-left">
                                {item.colors.map((c, index) => (
                                  <span
                                    key={index}
                                    className="catalog-item__color"
                                    style={{ background: c.code }}
                                  />
                                ))}
                              </div>
                            </div>
                            <div className="row" style={{ marginTop: 5 }}>
                              <div className="col text-left">
                                <h4 className="carousel-catalog-item__price">
                                  ${item.price / 100}
                                </h4>
                                {item.sale ? (
                                  <p className="carousel-catalog-item__old-price">
                                    {item.oldPrice}
                                  </p>
                                ) : ''}
                              </div>
                            </div>
                        </CardTitle>
                    </CardBody>
                </Card>
            </Link>
        ));

        const slickSettings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: (catalogItems.length > 5) ? 5 : catalogItems.length,
            slidesToScroll: (catalogItems.length > 5) ? 5 : catalogItems.length - 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: (catalogItems.length > 5) ? 5 : catalogItems.length,
                        slidesToScroll: (catalogItems.length > 5) ? 5 : catalogItems.length - 1,
                        infinite: true,
                        dots: false,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: (catalogItems.length > 5) ? 3 : (catalogItems.length - 1) || 1,
                        slidesToScroll: (catalogItems.length > 5) ? 3 : (catalogItems.length - 1) || 1,
                        infinite: true,
                        dots: false,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: (catalogItems.length > 5) ? 3 : (catalogItems.length - 1) || 1,
                        slidesToScroll: (catalogItems.length > 5) ? 3 : (catalogItems.length - 1) || 1,
                        infinite: true,
                        dots: false,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: true,
                    },
                },
            ],
        };

        return (
            <div
              className="block-slideshow block block-slideshow--layout--with-departments"
              style={{ marginTop: -35 }}>
                <div className="container">
                    {
                        (catalogItems.length)
                        ? <BlockHeader
                            title="Other items to consider"
                          />
                        : null
                    }
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <StroykaSlick {...slickSettings}>
                                {catalogItems}
                            </StroykaSlick>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

OtherItemsToConsiderCarousel.propTypes = {
    withDepartments: PropTypes.bool,
};

OtherItemsToConsiderCarousel.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => (
    {
        catalog: state.content.catalog_data,
        contentVersion: state.content.version,
        inspiration: state.inspiration.inspiration,
    });

export default withGATracker(connect(mapStateToProps)(OtherItemsToConsiderCarousel),'Other Items To Consider Carousel');
