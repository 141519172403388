// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardImg,
    CardTitle,
} from 'reactstrap';

// third-party
import PropTypes from 'prop-types';

// application
import departmentsAria from '../../services/departmentsArea';
import StroykaSlick from '../shared/StroykaSlick';

import BlockHeader from '../shared/BlockHeader';
import withTracker from '../GATracking/withGATracker';


class BlockRelatedItemsCarousel extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const {
            product: { related_items },
            catalog,
            contentVersion,
            pageLinkTo,
         } = this.props;
        const related_products = (related_items)
        ? catalog.filter((item) => related_items.filter(
            (related) => ((related.category === item.category) && (related.title === item.title)),
        ).length)
        : [];
        const items = related_products.map((item, i) => (
              <Link
                key={i}
                to={`/${contentVersion}/product_page/${item.category}/${item.title}`}
                onClick={() => pageLinkTo('Related Items Block', `${item.category}/${item.title}`)}
              >
                <Card>
                    <div style={{ width: 150, maxHeight: 150 }}>
                      <CardImg
                        top
                        src={item.image}
                        alt="Item to consider image"
                        style={{ width: 150, height: 150, alignSelf: 'center'}}
                      />
                    </div>
                    <CardBody style={{ paddingLeft: 5 }}>
                        <CardTitle
                          style={{ textAlign: 'left' }}
                        >
                            <div className="row">
                                  <div className="col-12 text-left">
                                    <h4 className="carousel-catalog-item__title">{item.title}</h4>
                                  </div>
                            </div>
                            <div className="row">
                              {item.colors.map((c, index) => (
                                <span
                                   key={index}
                                   className="catalog-item__color"
                                   style={{ background: c.code }}
                                />
                           ))}
                             </div>
                            <div className="row" style={{ marginTop: 5 }}>
                              <div className="col text-left">
                                <h4 className="carousel-catalog-item__price">
                                  ${item.price / 100}
                                </h4>
                                {item.sale ? (
                                  <p className="carousel-catalog-item__old-price">
                                    {item.oldPrice}
                                  </p>
                                ) : ''}
                              </div>
                            </div>
                        </CardTitle>
                    </CardBody>
                </Card>
            </Link>)
        );

        const slickSettings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: (items.length > 5) ? 5 : items.length,
            slidesToScroll: (items.length > 5) ? 5 : items.length - 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: (items.length > 5) ? 5 : items.length,
                        slidesToScroll: (items.length > 5) ? 5 : items.length - 1,
                        infinite: true,
                        dots: false,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: (items.length > 5) ? 3 : (items.length - 1) || 1,
                        slidesToScroll: (items.length > 5) ? 3 : (items.length - 1) || 1,
                        infinite: true,
                        dots: false,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: (items.length > 5) ? 3 : (items.length - 1) || 1,
                        slidesToScroll: (items.length > 5) ? 3 : (items.length - 1) || 1,
                        infinite: true,
                        dots: false,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: (items.length > 1) ? 2 : 1,
                        slidesToScroll: (items.length > 1) ? 2 : 1,
                        infinite: true,
                        arrows: true,
                    },
                },
            ],
        };

        return (
            <div
              className="block-slideshow block block-slideshow--layout--with-departments"
              style={{ marginTop: -50 }}>
                <div className="container">
                    {
                      (items.length)
                        ? <BlockHeader
                            title="Items to consider"
                          />
                        : null
                    }
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <StroykaSlick {...slickSettings}>
                                {items}
                            </StroykaSlick>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockRelatedItemsCarousel.propTypes = {
    withDepartments: PropTypes.bool,
};

BlockRelatedItemsCarousel.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => (
    {
        catalog: state.content.catalog_data,
        contentVersion: state.content.version,
        product: state.product.product,
    });

export default withTracker(connect(mapStateToProps)(BlockRelatedItemsCarousel), 'Related Items Carousel');
