export const LOAD_CONTENT_VERSION = 'LOAD_CONTENT_VERSION';
export const LOAD_CONTENT_ALL = 'LOAD_CONTENT_ALL';
export const LOAD_CATALOG_CATEGORIES = 'LOAD_CATALOG_CATEGORIES';
export const LOAD_CATALOG = 'LOAD_CATALOG';
export const LOAD_INSPIRATIONS = 'LOAD_INPIRATIONS';
export const LOAD_PRESENTATIONS = 'LOAD_PRESENTATIONS';
export const LOAD_ABOUT_US =  'LOAD_ABOUT_US';
export const LOAD_CONTACT_US = 'LOAD_CONTACT_US';


export function loadContentVersion(version){
    const action = {
        type: LOAD_CONTENT_VERSION,
        data: version,
    };

    return action;
};

export function loadContentAll(dta) {
    const action = {
        type: LOAD_CONTENT_ALL,
        data: dta
    };
    
    return action;
};

export function loadCatalogCategories(dta) {
    const action = {
        type: LOAD_CATALOG_CATEGORIES,
        data: dta,
    };

    return action;
};

export function loadCatalog(dta){
    const action = {
        type: LOAD_CATALOG,
        data: dta,
    };

    return action;
};

export function loadInspirations(dta){
    const action = {
        type: LOAD_INSPIRATIONS,
        data: dta,
    };

    return action;
};

export function loadPresentations(dta){
    const action = {
        type: LOAD_PRESENTATIONS,
        data: dta,
    };

    return action;
};

export function loadAboutUs(dta){
    const action = {
        type: LOAD_ABOUT_US,
        data: dta,
    };

    return action;
};

export function loadContactUs(dta){
    const action = {
        type: LOAD_CONTACT_US,
        data: dta,
    };

    return action;
};
