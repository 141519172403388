import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Col
} from 'reactstrap';

import { loadInspirationAction } from '../../../store/inspiration';

class InspirationPageCard extends React.Component {

    componentDidMount() {
        const { match: { params } } = this.props;
        this.fetchInspiration(params.title);
    }

    componentDidUpdate(prevProps) {
        const { match: { params } } = this.props;
        if (prevProps.match.params.title !== params.title) {
                this.fetchInspiration(params.title);
        }
    }

    fetchInspiration = (title) => {
        const { inspirations, setInspiration } = this.props;
        let inspiration = inspirations.find((item) => (item.title === title));
        setInspiration(inspiration);
    }

    render() {
        const { inspiration: { image, title, description } } = this.props;
        return (
            <Col md={12} lg={12} sm={12} xs={12}>
                <div className="inspiration-card">
                    <div className="inspiration-gallery">
                        <span className="inspiration-gallery__current-img">
                            <img src={image || null} alt="inspiration-img" />
                        </span>
                    </div>
                    <div className="inspiration-card__info">
                        <h3 className="inspiration-card__title">{title}</h3>
                        <p>{description}</p>
                    </div>
                </div>
            </Col>
        );
    }
}

function mapDispatcherToProps(dispatcher) {
    return {
        setInspiration: (inspiration) => dispatcher(loadInspirationAction(inspiration)),
    };
}

function mapStateToProps(state) {
    return {
        inspiration: state.inspiration.inspiration,
        inspirations: state.content.inspiration,
        contentVersion: state.content.version,
    };
}

export default connect(mapStateToProps, mapDispatcherToProps)(withRouter(InspirationPageCard));