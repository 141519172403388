import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    UPDATE_CART_ITEM,
    UPDATE_DELIVERY_INFO,
    UPDATE_PICKUP_DATE,
    RESET_CART,
    UPDATE_DELIVERY_CHOICE,
    RESET_PICKUP_DATE,
} from './cartAction';

const FLTAX = 0.065;
const resetDeliveryInfo = {
    startDate: null,
    endDate: null,
    address: {
        address: '',
        city: '',
    },
};
const resetPickupDate = {
    startDate: null, 
    endDate: null,
}

const initialState = {
    itemsInCart: [],
    subTotal: 0,
    deliveryCost: null,
    chairsInCart: 0,
    totalTax: 0,
    deliveryInfo: resetDeliveryInfo,
    pickupDate: resetPickupDate,
    isDeliverySelected: null,
    extraRentalDays: 0,
};


const calculateExtraRentalDay = ({startDate, endDate}) => {
    const startWeekday = new Date(startDate).getDay();
    const weekendDayCount = (startWeekday === 5) ? 1 : 0;
    const extraRentalDay = Math.floor((endDate - startDate) / (1000 * 3600 * 24)) - 1 - weekendDayCount;
    return (extraRentalDay > 1) ? (extraRentalDay - 1) * .5 : 0;
}

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
    case ADD_TO_CART: {
        let newItemsInCart = [...state.itemsInCart];
        let foundItem = false;
        const {
            data: {
                title,
                selectedColor,
                category,
                quantity,
                total,
            },
        } = action;
        newItemsInCart = newItemsInCart.map((i) => {
            if (!((i.title === title)
            && (i.selectedColor === selectedColor)
            && (i.category === category))) {
                return i;
            }
            foundItem = true;
            return {
                ...i,
                quantity: i.quantity + quantity,
                total: i.total + total,
            };
        });
        if (!foundItem) {
            newItemsInCart.push(action.data);
        }
        const newChairsInCart = newItemsInCart.reduce((acc, curr) => ((curr.category.toLowerCase().includes('chair')) ? acc + curr.quantity : acc), 0);
        const newSubTotal = newItemsInCart.reduce((acc, curr) => (acc + curr.total), 0);
        return {
            ...state,
            itemsInCart: newItemsInCart,
            subTotal: newSubTotal,
            totalTax: (newSubTotal + (state.extraRentalDays * newSubTotal) + state.deliveryCost) * FLTAX,
            chairsInCart: newChairsInCart,

        };
    }
    case REMOVE_FROM_CART: {
        const { data: { title, selectedColor, category } } = action;
        let removedItemCart = [...state.itemsInCart];
        removedItemCart = removedItemCart.filter((i) => (
            (i.title + i.selectedColor + i.category) !== (title + selectedColor + category)));
        const updatedSubTotal = removedItemCart.reduce((acc, curr) => (acc + curr.total), 0);
        const removedChairsInCart = removedItemCart.reduce((acc, curr) => ((curr.category.toLowerCase().includes('chair')) ? acc + curr.quantity : acc), 0);
        return {
            ...state,
            deliveryCost: (removedItemCart.length) ? state.deliveryCost : 0,
            deliveryInfo: (removedItemCart.length) ? state.deliveryInfo : resetDeliveryInfo,
            itemsInCart: removedItemCart,
            subTotal: updatedSubTotal,
            totalTax: (updatedSubTotal + (state.extraRentalDays * updatedSubTotal) + state.deliveryCost) * FLTAX,
            chairsInCart: removedChairsInCart,
        };
    }
    case UPDATE_CART_ITEM: {
        const { itemsInCart } = state;
        const { data: { item: { title, selectedColor, category } } } = action;
        const updatedItemsInCart = itemsInCart.map((i) => (((i.title === title)
        && (i.selectedColor === selectedColor)
        && (i.category === category))
            ? {
                ...i,
                quantity: action.data.quantity,
                total: action.data.total,
            }
            : i));
        const newSubTotal = updatedItemsInCart.reduce((acc, curr) => (acc + curr.total), 0);
        const updatedChairsInCart = updatedItemsInCart.reduce((acc, curr) => ((curr.category.toLowerCase().includes('chair')) ? acc + curr.quantity : acc), 0);
        return {
            ...state,
            itemsInCart: updatedItemsInCart,
            subTotal: newSubTotal,
            totalTax: (newSubTotal + (state.extraRentalDays * newSubTotal) + state.deliveryCost) * FLTAX,
            chairsInCart: updatedChairsInCart,
        };
    }
    case UPDATE_DELIVERY_INFO: {
        const { data: { deliveryCost, deliveryInfo } } = action;
        return { 
            ...state,
            deliveryCost,
            extraRentalDays: 0,
            totalTax: (state.subTotal + deliveryCost.total_delivery_cost + deliveryCost.total_pickup_cost) * FLTAX,
            deliveryInfo };
    }

    case UPDATE_DELIVERY_CHOICE: {
        const { data: { isDeliverySelected } } = action;
        const updatedDeliveryCost = (!isDeliverySelected) ? 0: state.deliveryCost; 
        const extraRentalCharge = (isDeliverySelected) ? 0 : (state.extraRentalDays * state.subTotal);  
        return {
            ...state,
            isDeliverySelected,
            deliveryCost: updatedDeliveryCost,
            totalTax: (state.subTotal + extraRentalCharge + updatedDeliveryCost) * FLTAX,
        };
    }

    case UPDATE_PICKUP_DATE: {
        const { data: { pickupDate } } = action;
        const extraRentalDays = calculateExtraRentalDay(pickupDate);
        return {
             ...state,
             pickupDate,
             extraRentalDays,
             deliveryCost: 0,
             totalTax: (state.subTotal + (extraRentalDays * state.subTotal) ) * FLTAX,
            };
    }

    case RESET_PICKUP_DATE: {
        return { ...state, pickupDate: resetPickupDate, extraRentalDays: 0};
    }

    case RESET_CART: 
        return initialState;

    default:
        return state;
    }
}
