/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CartOutlineIcon from 'mdi-react/CartOutlineIcon';

class CartIndicator extends PureComponent {
  onClick = () => {
      const { history: { push }, contentVersion } = this.props;
      push(`/${contentVersion}/cart`);
  };

  render() {
      const { subTotal } = this.props;

      return (
          <div>
              <button className="cart-icon__btn cart-icon__btn--new" type="button" onClick={this.onClick}>
                  <CartOutlineIcon />
                  <div className={(subTotal) ? 'cart-icon__btn-new-label' : null}>
                      <div />
                  </div>
                  { (subTotal)
                      ? (
                          <span style={{ margin: 5 }}>
$
                              {subTotal.toFixed(2)}
                          </span>
                      )
                      : null}
              </button>
          </div>
      );
  }
}

CartIndicator.propTypes = {
    subTotal: PropTypes.number,
};

CartIndicator.defaultProps = {
    subTotal: 0,
};

const mapStateToProps = (state) => (
    {
        subTotal: state.cart.subTotal,
        contentVersion: state.content.version,
    });

export default connect(mapStateToProps)(withRouter(CartIndicator));
