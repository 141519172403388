// react
import React from 'react';
import { connect } from 'react-redux';

import DatePicker from 'react-datepicker';

// third-party
import { Helmet } from 'react-helmet-async';

import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

// blocks
import BlockMap from '../blocks/BlockMap';

import UserInfoVerification from '../UserInfoVerification';

import withGATracker from '../GATracking/withGATracker';

const { REACT_APP_FESTEJOSWEB_BACKEND_URL } = process.env

//If date is not within acceptable business day, return next acceptable business day
// - Appointment should not be scheduled for same day 
// - Sundays and Saturdays are not appointments days
function checkStartingDate(startDate){
    startDate.setDate(startDate.getDate() + 1);
    startDate.setDate(
        ((startDate.getDay() === 0)  || (startDate.getDay() === 6))
        ? startDate.getDate() + 2
        : startDate.getDate());
    return startDate;
}

//Appointments cannot be set for Sundays and Saturdays 
//Appointments should not be scheduled for same day 
function filterDays(date){
    const currentDate = new Date();
    const dateValue = date.getFullYear().toString() +
                      date.getMonth().toString() +
                      date.getDate().toString();
    const currentDateValue = currentDate.getFullYear().toString() +
                             currentDate.getMonth().toString() +
                             currentDate.getDate().toString(); 
    
    return (
        (date.getDay() !== 0) && 
        (date.getDay() !== 6) && 
        (dateValue !== currentDateValue) &&
        (date > currentDate));
}

const verifyResponse = (r) => {
    if (r.ok) {
        return r;
      }
    if (r.status === 400) {
      throw Error('Invalid Code')
    }
  };

class SitePageContactUs extends React.Component {
    constructor() {
        super();
        this.state = {
            appointmentDate: null,
            errorMessage: null,
        };

        this.onSendAppointmentRequest = this.onSendAppointmentRequest.bind(this);
    }

    handleChangeStart = (changedStartDate) => {
        let newStartDate = changedStartDate;
        if (changedStartDate <= new Date()) {
            newStartDate = checkStartingDate(new Date());
        } 
        
        this.setState({ appointmentDate: newStartDate });
    }

    async onSendAppointmentRequest(userData) {
        const {
            email,
            fullName,
            phoneNumber,
            verificationCode, 
        } = userData; 

        const { googleReCaptchaProps, pageGAEvent } = this.props;

        const { appointmentDate } = this.state;

        const token = await googleReCaptchaProps.executeRecaptcha('request_virtual_appointment');

        pageGAEvent('Send Appointment Request', 'Send Appointment Request');

        return fetch(`${REACT_APP_FESTEJOSWEB_BACKEND_URL}appointmentrequest`,
        {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                code: verificationCode,
                name: fullName,
                phone: phoneNumber,
                date: Math.trunc(new Date(appointmentDate).getTime() / 1000),
            }),
        }).then(response => verifyResponse(response))
          .then(data => data);
    }

    resetAppointmentDate = () => {
        const { pageGAEvent } = this.props;
        pageGAEvent('Send Appointment Request - User Confirmed', 'Send Appointment Request - User Confirmed');
        this.setState({appointmentDate: null, errorMessage: null});
    } 

    render() {
      const {
          appointmentDate,
          errorMessage,
      } = this.state;

      const smallDevice = window.matchMedia('(max-width: 440px)').matches;
      return (
        <React.Fragment>
            <Helmet>
                <title>{`Contact Us`}</title>
            </Helmet>

            <BlockMap />

            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">Request your virtual appointment</h4>
                                        <p>As the country reopens and we adapt to the new normal due to COVID-19, Festejos will be taking all meassures to make this a safe transition for our employees and our customers. At this time our showroom is open only for virtual appointments.</p>
                                        <p>Our showroom is equiped with the necessary technology that allow us to showcase to you our products in the most natural social interaction posible within a virtual environment.</p>
                                        <p>Call us, or fill out this form to request your virtual appointment. A decor consultant will contact you to confirm your appointment date. </p>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                      <div className="form-group col-md-12 col-lg-12">
                                          {(!errorMessage)
                                            ? <label htmlFor="form-date">Desire appointment date</label>
                                            : <span style={{ color: 'red' }}>{errorMessage}</span>
                                          }
                                          <div>
                                            <DatePicker
                                              id="form-date"
                                              selected={appointmentDate}
                                              startDate={appointmentDate}
                                              filterDate={filterDays}
                                              onChange={this.handleChangeStart}
                                              showTimeSelect
                                              maxTime={new Date('January 1, 1970 00:00:00').setHours(14)}
                                              minTime={new Date('January 1, 1970 00:00:00').setHours(9)}
                                              dateFormat="Pp"
                                              dropDownMode="select"
                                              withPortal={smallDevice}
                                            />
                                         </div>
                                       </div>
                                       {(!errorMessage && appointmentDate)
                                          ?  <UserInfoVerification
                                              onSubmit={this.onSendAppointmentRequest}
                                              succesMessage="We sent you an email with your appointment request.  One of our decor consultant will contact you to confirm your appointment date."
                                              submitButtonLabel="Send Request"
                                              verifyBeforeSending={() => (true)}
                                              eventually={this.resetAppointmentDate}
                                            />
                                          : null
                                       }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ contactUs: state.content.contact_us });

export default withGATracker(withGoogleReCaptcha(connect(mapStateToProps)(SitePageContactUs)), 'Contact Us');
