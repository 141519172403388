/* eslint-disable linebreak-style */
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import ProductCard from './components/ProductCard';
import BlockRelatedItemsCarousel from '../blocks/BlockRelatedItemsCarousel';
import BlockRelatedProductInspirationsCarousel from '../blocks/BlockRelatedProductInspirationsCarousel';
import { resetBreadCrumbs } from '../../store/product';

import { Helmet } from 'react-helmet-async';

class ProductPage extends React.Component {
    componentWillUnmount() {
        const { resetProductsBreadCrumbs } = this.props;
        resetProductsBreadCrumbs();
    }

    render() {
        return (
            <React.Fragment>
              <Helmet>
                <title>Product Page</title>
              </Helmet>
            <Container>
                <Row>
                    <ProductCard />
                </Row>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                      <BlockRelatedItemsCarousel />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                      <BlockRelatedProductInspirationsCarousel />
                    </Col>
                </Row>
            </Container>
          </React.Fragment>
        );
    }
}

const mapDispatcherToProps = (dispatcher) => ({
    resetProductsBreadCrumbs: () => dispatcher(resetBreadCrumbs()),
});

export default connect(null, mapDispatcherToProps)(ProductPage);
