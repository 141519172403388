export default function headerNavigation(version, categories) {
    const contentVersion = (version) ? `/${version}` : '';
    const catalogCategories = categories.map((c) => ({
        title: c.category,
        url: `/${version}/catalog/${c.category}`,
        image: c.image,
    }));
    const categoryColumns = [];
    let links = [];
    for (let i = 1; i <= catalogCategories.length; i += 1) {
        if ((i % 5) === 0) {
            links.push(catalogCategories[i - 1]);
            categoryColumns.push({ size: 4, links });
            links = [];
            continue;
        }
        links.push(catalogCategories[i - 1]);
    }
    if (links.length) {
        categoryColumns.push({ size: 4, links });
    }

    return [
        {
            title: 'Home',
            url: `${contentVersion}`,
        },
        {
            title: 'Catalog',
            submenu: {
                type: 'megamenu',
                menu: {
                    size: 'md',
                    columns: categoryColumns,
                },
            },
        },
        {
            title: 'Inspirations',
            url: `${contentVersion}/inspirations`,
        },
        {
            title: 'About Us',
            url: `${contentVersion}/site/about-us`,
        },
        {
            title: 'Service',
            url: `${contentVersion}/site/service`,
        },
        {
            title: 'Contact Us',
            url: `${contentVersion}/site/contact-us`,
        },
    ];
}
