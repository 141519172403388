// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import {
    BrowserRouter,
    Route,
    Switch,
} from 'react-router-dom';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ScrollContext } from 'react-router-scroll-4';

import 'bootstrap/dist/css/bootstrap.css';

// application
import languages from '../i18n';
import { localeChange } from '../store/locale';

// pages
import Layout from './Layout';
import HomePage from './home/HomePage';
import GATrackerProvider from './GATracking/GATrackerProvider';


class Root extends Component {
    componentDidMount() {
        // preloader
        /*setTimeout(() => {
            const preloader = document.querySelector('.site-preloader');

            preloader.addEventListener('transitionend', (event) => {
                if (event.propertyName === 'opacity') {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add('site-preloader__fade');
        }, 1000);*/

        // this is for demo only, you can delete it
        const { localeChange: changeLocale } = this.props;
        const direction = new URLSearchParams(window.location.search).get('dir');

        if (direction !== null) {
            changeLocale(direction === 'rtl' ? 'ar' : 'en');
        }
    }

    render() {
        const { locale } = this.props;
        const { direction } = languages[locale];
        const { REACT_APP_GOOGLE_RECAPTCHA_KEY } = process.env; 

        return (
            <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_GOOGLE_RECAPTCHA_KEY}>
                <BrowserRouter basename="/">
                    <GATrackerProvider>
                        <HelmetProvider>
                            <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                            <ScrollContext>
                                <Switch>
                                    <Route
                                        exact path="/"
                                        render={(props) => (
                                            <Layout {...props} headerLayout="default" homeComponent={HomePage} />
                                        )}
                                    />
                                    <Route
                                        path="/:version"
                                        render={(props) => (
                                            <Layout {...props} headerLayout="default" homeComponent={HomePage} />
                                        )}
                                    />
                                </Switch>
                            </ScrollContext>
                        </HelmetProvider>
                    </GATrackerProvider>
                </BrowserRouter>
            </GoogleReCaptchaProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
