import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import CenterLoadingSpinner from './shared/CenterLoadingSpinner';
import { loadContentAll, loadCatalogCategories } from '../store/content';

const { REACT_APP_FESTEJOSWEB_BACKEND_URL } = process.env;

const verifyResponse = (r) => {
    if (r.ok) {
        if (r.status === 204) {
            return 'Success';
        }
        return r.json();
    }
    if (r.status === 404) {
        throw Error('Error');
    }
    return r.json().then((err) => {
        throw Error(err);
    });
};

async function retrieveContent(content, version, googleToken) {
    const data = await fetch(`${REACT_APP_FESTEJOSWEB_BACKEND_URL}${(version) ? `${version}/` : ''}?content=${content}`, {
        method: 'GET',
        headers: {
            Authorization: googleToken,
        },
    })
        .then((response) => verifyResponse(response))
        .then((dta) => dta );
    
    return data;
} 

function ContentProvider(props) {
    const {
        children,
        setContentAll,
        setContentCategories,
        version,
     } = props;

    const [ contentLoaded, setContentLoaded ] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
      async function fetchData () {
        const token = await executeRecaptcha('retrieve_content');
        retrieveContent('categories', version, token)
          .then((dta) => setContentCategories(dta))
          .then(() => {
            retrieveContent('all', version, token)
              .then((dta) => setContentAll(dta))
              .then(() => setContentLoaded({contentLoaded: true}));
          });
        }
        fetchData();
    }, [version, setContentAll, setContentCategories, executeRecaptcha]);

    return (
      <CenterLoadingSpinner loading={!contentLoaded}>
        {
          children
        }
      </CenterLoadingSpinner>
    );
}

const mapDispatcherToProps = (dispatcher) => (
    {
        setContentAll: (content) => dispatcher(loadContentAll(content)),
        setContentCategories: (categories) => dispatcher(loadCatalogCategories(categories)),
    });

export default connect(null, mapDispatcherToProps)(ContentProvider);
