import React from 'react';
import { Button } from 'reactstrap';

class PresentationImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: props.image,
            description: props.description,
        };
    }

    render() {
        const { image, description } = this.state;
        const { onShopClick } = this.props;
        return (
                <div
                  onClick={() => onShopClick()}
                  className="presentation-image">
                    <div className="presentation-image__img-wrap">
                        <img className="presentation-image__img" src={image} alt="slide" />
                        <div className="presentation-image__caption">
                            <div className="block-slideshow__slide-button">
                                <p className="presentation__caption-title" style={{ marginBottom: 10 }}>{description}</p>
                                <Button color="primary" onClick={() => onShopClick()}>Rent Now</Button>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default PresentationImage;
