import {
    LOAD_CONTENT_ALL,
    LOAD_CATALOG_CATEGORIES,
    LOAD_CONTENT_VERSION,
    LOAD_CATALOG,
    LOAD_INSPIRATIONS,
    LOAD_PRESENTATIONS,
    LOAD_ABOUT_US,
    LOAD_CONTACT_US
} from './contentActions';

const initialState = {
    about_us: '',
    version: '',
    contact_us: '',
    inspiration: [],
    catalog_data: [],
    presentation: [],
    categories: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
    case LOAD_CONTENT_ALL:
        return { ...state, ...action.data };
    case LOAD_CATALOG_CATEGORIES:
        return { ...state, ...action.data };
    case LOAD_CONTENT_VERSION:
        return { ...state, version: action.data };
    case LOAD_CATALOG:
        return { ...state, ...action.data };
    case LOAD_INSPIRATIONS:
        return { ...state, ...action.data };
    case LOAD_PRESENTATIONS:
        return { ...state, ...action.data };
    case LOAD_ABOUT_US:
        return { ...state, ...action.data };
    case LOAD_CONTACT_US:
        return { ...state, ...action.data };
    default:
        return state;
    }
}
