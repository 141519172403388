/* eslint-disable linebreak-style */
import React from 'react';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Col,
    ButtonToolbar,
    Button,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ProductGallery from './ProductGallery';
import ColorSelect from './ColorSelect';
import InputNumber from '../../shared/InputNumber';
import { loadProductAction, addToBreadCrumbs } from '../../../store/product';
import { addToCart } from '../../../store/cart';
import { ImageNotification } from '../../shared/Notification/index';
import BreadCrumb from '../../shared/BreadCrumb';
import withGATracker from '../../GATracking/withGATracker';

class ProductCard extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedColor: {
                value: '',
                label: '',
                color: '',
                image: '',
            },
            selectedQuantity: '',
            invalidQuantityError: null,
        };
        this.onColorSelect = this.onColorSelect.bind(this);
        this.onQuantityChange = this.onQuantityChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.resetSelection = this.resetSelection.bind(this);
    }


    componentDidMount() {
        const { match: { params } } = this.props;
        this.fetchCategoryProduct(params.category, params.title);
    }

    componentDidUpdate(prevProps) {
        const { match: { params } } = this.props;
        if (
            (prevProps.match.params.category !== params.category)
            || (prevProps.match.params.title !== params.title)) {
            this.fetchCategoryProduct(params.category, params.title);
        }
    }

    onColorSelect(value) {
        this.setState({ selectedColor: value.value });
    }

    onQuantityChange(q) {
        const { value } = q.currentTarget;
        this.setState({ selectedQuantity: value, invalidQuantityError: null });
    }

    onSubmit(v) {
        v.preventDefault();
        if (this.isValidQuantity()) {
            const { selectedQuantity, selectedColor } = this.state;
            const { product: { title, price, category }, addToCart, pageGAEvent } = this.props;
            const total = Number((((price / 100) * selectedQuantity)).toFixed(2));
            const dataToSubmit = {
                category,
                img: selectedColor.image,
                selectedColor: selectedColor.label,
                title,
                quantity: Number(selectedQuantity),
                price: (price / 100),
                total,
            };
            addToCart(dataToSubmit).then(() => ImageNotification('right-up', `${title}, ${selectedColor.label}`, selectedColor.image, `${dataToSubmit.quantity} x $${dataToSubmit.price} added to cart`));
            this.resetSelection();
            pageGAEvent('Product Page - Add to Cart', `${category}/${title}`);
        }
    }

    resetSelection() {
        this.setState({ selectedQuantity: '' });
    }

    fetchCategoryProduct(category, title) {
        const { catalog } = this.props;
        let product = catalog.filter((item) => (
            (item.category === category) && (item.title === title)));
        product = (product.length)
            ? product[0]
            : {
                image: '',
                related_images: [],
                colors: [],
                category: '',
                title: '',
                price: '',
                description: '',
                short_description: '',
            };
        const imgs = [];
        imgs.push({ src: product.image });
        for (let i = 0; i < product.related_images.length; i += 1) {
            imgs.push({ src: product.related_images[i] });
        }
        const { setProduct, addProductToBreadCrumbs, contentVersion } = this.props;
        const { colors } = product;
        const firstColor = (colors.length) ? colors[0] : { description: '', code: '', image: '' };
        this.setState({
            selectedColor: {
                value: firstColor.description,
                label: firstColor.description,
                color: firstColor.code,
                image: firstColor.image,
            },
        });
        setProduct({
            product: {
                category: product.category,
                title: product.title,
                price: product.price,
                description: product.description,
                short_description: product.short_description,
                colors: product.colors,
                images: imgs,
                current_image_preview: (imgs.length) ? imgs[0].src : null,
                related_items: product.related_items,
            },
        });
        addProductToBreadCrumbs({
            category: { title: product.category, url: `/${contentVersion}/catalog/${product.category}`},
            product: { title: product.title, url: `/${contentVersion}/product_page/${product.category}/${product.title}`}
        });
    }

    isValidQuantity() {
        const { selectedQuantity } = this.state;
        if (!selectedQuantity) {
            this.setState({ invalidQuantityError: 'Quantity is required' });
            return false;
        }
        return true;
    }

    render() {
        const {
            product: {
                title,
                price,
                description,
                colors,
            },
            breadcrumbs,
        } = this.props;
        const { selectedQuantity, selectedColor, invalidQuantityError } = this.state;
        return (
            <Col md={12} lg={12}>
                <BreadCrumb
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  breadcrumb={breadcrumbs}
                />
                <Card>
                    <CardBody>
                        <div className="product-card">
                            <ProductGallery />
                            <div className="product-card__info">
                                <h3 className="product-card__title">{title}</h3>
                                <h1 className="product-card__price">
$
                                    {price / 100}
                                </h1>
                                <p>{description}</p>
                                <form className="form product-card__form" onSubmit={this.onSubmit}>
                                    <div className="form__half">
                                        <div className="form__form-group">
                                            <span className="form__form-group-label product-card__form-label">Select Color</span>
                                            <div className="form__form-group-field">
                                                <ColorSelect
                                                    options={colors.map((c) => ({
                                                        value: c.description,
                                                        label: c.description,
                                                        color: c.code,
                                                        image: c.image,
                                                    }))}
                                                    value={selectedColor}
                                                    onChange={this.onColorSelect}
                                                />
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            {(invalidQuantityError)
                                                ? <span className="form__form-group-label product-card__form-label" style={{ color: 'red' }}>{invalidQuantityError}</span>
                                                : <span className="form__form-group-label product-card__form-label">Quantity</span>}
                                            <div className="form__form-group-field">
                                                <InputNumber name="quantity" onChange={this.onQuantityChange} min={1} value={selectedQuantity} />
                                            </div>
                                        </div>
                                        <ButtonToolbar className="product-card__btn-toolbar">
                                            <Button color="primary" type="submit">Add to cart</Button>
                                        </ButtonToolbar>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

function mapDispatcherToProps(dispatcher) {
    return {
        setProduct: (product) => dispatcher(loadProductAction(product)),
        addToCart: (product) => new Promise((resolve) => resolve(dispatcher(addToCart(product)))),
        addProductToBreadCrumbs: (product) => dispatcher(addToBreadCrumbs(product)),
    };
}

function mapStateToProps(state) {
    return {
        product: state.product.product,
        breadcrumbs : state.product.breadcrumbs,
        catalog: state.content.catalog_data,
        contentVersion: state.content.version,
    };
}

export default withGATracker(connect(mapStateToProps, mapDispatcherToProps)(withRouter(ProductCard)), 'Product Page');
