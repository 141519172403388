/* eslint-disable linebreak-style */
import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ProductItems from '../../shared/ProductItems/ProductItems';
import withGATracker from '../../GATracking/withGATracker';

import { Helmet } from 'react-helmet-async';

class ProductList extends React.Component {
    constructor() {
        super();
        this.state = {
            category: '',
            products: [],
        };
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.fetchCategoryProducts(params.category);
    }

    componentDidUpdate(prevProps) {
        const { match: { params } } = this.props;
        if (params.category !== prevProps.match.params.category) {
            this.fetchCategoryProducts(params.category);
        }
    }

    fetchCategoryProducts(category) {
        const { catalog } = this.props;
        const products = catalog.filter((item) => (item.category === category));
        this.setState({
            category: products[0].category,
            products,
        });
    }

    render() {
        const { category, products } = this.state;

        return (
            <React.Fragment>
              <Helmet>
                <title>{`Catalog - ${category}`}</title>
              </Helmet>
              <Container>
                <Row>
                    <Col md={12}>
                        <h3 className="page-title">{category}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                        <ProductItems {...this.props} items={products} category={category} />
                    </Col>
                </Row>
              </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({ catalog: state.content.catalog_data });

export default withGATracker(connect(mapStateToProps)(withRouter(ProductList)), 'Catalog Category');
