/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import cartPlus from '@iconify/icons-mdi/cart-plus';
import MenuCollapse from './MenuCollapse';
import { addToCart } from '../../store/cart';
import { ImageNotification } from '../shared/Notification/index';
import withGATracker from '../GATracking/withGATracker';

const setCartItem = (quantity, color, title, price, category) => {
    const total = Number((((price / 100) * quantity)).toFixed(2));
    const dataToSubmit = {
        category,
        img: color.image,
        selectedColor: color.description,
        title,
        quantity: Number(quantity),
        price: (price / 100),
        total,
    };
    return dataToSubmit;
};

class InspirationImage extends React.PureComponent {
    onSubmitToCart(color, title, price, category) {
        const { addToCart, flTaxRate, pageLinkTo } = this.props;
        const data = setCartItem(1, color, title, price, category, flTaxRate);
        addToCart(data).then(() => ImageNotification('right-up', `${title}, ${color.description}`, color.image, `${data.quantity} x $${data.price} added to cart`));
        pageLinkTo('Feature Items Tag - Add to Cart', `${category}/${title}`);
    }

    render() {
        const {
            image,
            title,
            featuredItems,
            contentVersion,
            pageLinkTo
        } = this.props;
        return (
            <div className="inspiration-image">
                <div className="inspiration-image__img-wrap">
                    <Link
                      to={`/${contentVersion}/inspiration_page/${title}`}
                      onClick={() => pageLinkTo('Inspiration Image', `${title}`)}
                    >
                      <img className="inspiration-image__img" src={image} alt="slide"/>
                    </Link>
                    <div className="inspiration-image__menu">
                        <MenuCollapse>
                            <div className="featured-items">
                                <div className="featured-items__products-list">
                                    <div className="featured-items__title">
                                        <h5>Featured Products</h5>
                                    </div>
                                    {
                                        featuredItems.map((item, i) => (
                                            <div key={i} className="featured-items__product">
                                                <div className="featured-items__product-image">
                                                    <Link
                                                      to={`/${contentVersion}/product_page/${item.category}/${item.title}`}
                                                      onClick={() => pageLinkTo('Feature Products Tag', `${item.category}/${item.title}`)}
                                                    >
                                                        <img src={item.extra_data.image} alt="" />
                                                    </Link>
                                                </div>
                                                <div className="featured-items__product-info">
                                                    <div className="featured-items__product-name">
                                                        <Link
                                                          to={`/${contentVersion}/product_page/${item.category}/${item.title}`}
                                                          onClick={() => pageLinkTo('Feature Products Tag', `${item.category}/${item.title}`)}
                                                        >
                                                            {item.title}
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <span className="featured-items__product-price">{`$${Number(item.extra_data.price) / 100}`}</span>
                                                    </div>
                                                </div>
                                                <div style={{ alignItems: 'right', paddingLeft: 30 }}>
                                                    <div
                                                        tabIndex={i}
                                                        role="button"
                                                        className="inspiration-image__shop-btn"
                                                        onClick={() => this.onSubmitToCart(
                                                            {
                                                                image: item.extra_data.image,
                                                                description: item.extra_data.color_description,
                                                            },
                                                            item.title,
                                                            item.extra_data.price,
                                                            item.category,
                                                        )}
                                                        onKeyDown={() => this.onSubmitToCart(
                                                            {
                                                                image: item.extra_data.image,
                                                                description: item.extra_data.color_description,
                                                            },
                                                            item.title,
                                                            item.extra_data.price,
                                                            item.category,
                                                        )}
                                                    >
                                                        <Icon
                                                            icon={cartPlus}
                                                            color="cornflowerblue"
                                                            width="20"
                                                            height="20"
                                                            align="center"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </MenuCollapse>

                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatcherToProps = (dispatcher) => ({
    addToCart: (product) => new Promise((resolve) => resolve(dispatcher(addToCart(product)))),
});

const mapStateToProps = (state) => (
    {
        contentVersion: state.content.version,
    });

export default withGATracker(connect(mapStateToProps, mapDispatcherToProps)(InspirationImage), 'Inspiration Image');
