import React, { PureComponent } from 'react';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import { Helmet } from 'react-helmet-async';

import InspirationPageCard from './components/InspirationPageCard';
import BlockFeatureItemsCarousel from './components/BlockFeatureItemsCarousel';
import OtherItemsToConsiderCarousel from './components/OtherItemsToConsiderCarousel';

import withGATracker from '../GATracking/withGATracker';

class InspirationPage extends PureComponent {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                  <title>Inspiration Page</title>
                </Helmet>
                <Container>
                    <Row>
                        <InspirationPageCard />
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                        <BlockFeatureItemsCarousel />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                        <OtherItemsToConsiderCarousel />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default withGATracker(InspirationPage, 'Inspiration Page');
