// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardImg,
    CardTitle,
    Button,
} from 'reactstrap';

// third-party
import PropTypes from 'prop-types';

import { addToCart } from '../../../store/cart';

// application
import departmentsAria from '../../../services/departmentsArea';
import StroykaSlick from '../../shared/StroykaSlick';

import BlockHeader from '../../shared/BlockHeader';
import { ImageNotification } from '../../shared/Notification/index';

import withGATracker from '../../GATracking/withGATracker';

const setCartItem = (quantity, color, title, price, category) => {
    const total = Number((((price / 100) * quantity)).toFixed(2));
    const dataToSubmit = {
        category,
        img: color.image,
        selectedColor: color.description,
        title,
        quantity: Number(quantity),
        price: (price / 100),
        total,
    };
    return dataToSubmit;
};

class BlockFeatureItemsCarousel extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onSubmitToCart(color, title, price, category) {
        const { addToCart, flTaxRate, pageGAEvent } = this.props;
        const data = setCartItem(1, color, title, price, category, flTaxRate);
        addToCart(data).then(() => ImageNotification('right-up', `${title}, ${color.description}`, color.image, `${data.quantity} x $${data.price} added to cart`));
        pageGAEvent('Featured Items - Add to Cart', `${title}/${category}`);
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { inspiration: { items }, contentVersion, pageLinkTo } = this.props;
        const featuredItems = items.map((item, i) => (
            <div>
                <Link
                  key={i}
                  to={`/${contentVersion}/product_page/${item.category}/${item.title}`}
                  onClick={() => pageLinkTo('Featured Items Carousel', `${item.category}/${item.title}`)}
                  >
                    <Card style={{ height: 250 }}>
                        <div style={{ width: 150, maxHeight: 150 }}>
                            <CardImg
                                top
                                src={item.extra_data.image}
                                alt="Featured item image"
                                style={{ width: 150, height: 150, alignSelf: 'center' }}
                            />
                        </div>
                        <CardBody style={{ paddingLeft: 5 }}>
                            <CardTitle
                                style={{ textAlign: 'left' }}
                            >
                                <div className="row">
                                    <div className="col-12 text-left">
                                        <h4 className="carousel-catalog-item__title">{item.title}</h4>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: 5 }}>
                                    <div className="col-6 text-left" style={{ paddingRight: 10 }}>
                                        <h4 className="carousel-catalog-item__price">
                                            ${item.extra_data.price / 100}
                                        </h4>
                                    </div>
                                </div>
                            </CardTitle>
                        </CardBody>
                    </Card>
                </Link>
                <Button
                    color="primary"
                    onClick={() => this.onSubmitToCart(
                        {
                            image: item.extra_data.image,
                            description: item.extra_data.color_description,
                        },
                        item.title,
                        item.extra_data.price,
                        item.category,
                    )}
                >
                    Add to Cart
                </Button>
            </div>
        ));

        const slickSettings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: (items.length > 5) ? 5 : items.length,
            slidesToScroll: (items.length > 5) ? 5 : items.length - 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: (items.length > 5) ? 5 : items.length,
                        slidesToScroll: (items.length > 5) ? 5 : items.length - 1,
                        infinite: true,
                        dots: false,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: (items.length > 5) ? 3 : (items.length - 1) || 1,
                        slidesToScroll: (items.length > 5) ? 3 : (items.length - 1) || 1,
                        infinite: true,
                        dots: false,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: (items.length > 5) ? 3 : (items.length - 1) || 1,
                        slidesToScroll: (items.length > 5) ? 3 : (items.length - 1) || 1,
                        infinite: true,
                        dots: false,
                        arrows: true,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: (items.length > 1) ? 2 : 1,
                        slidesToScroll: 1,
                        arrows: true,
                    },
                },
            ],
        };
        
        return (
            <div
                className="block-slideshow block block-slideshow--layout--with-departments">
                <div className="container">
                    <BlockHeader title="Featured Items" />
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <StroykaSlick {...slickSettings}>
                                {featuredItems}
                            </StroykaSlick>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockFeatureItemsCarousel.propTypes = {
    withDepartments: PropTypes.bool,
};

BlockFeatureItemsCarousel.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    inspiration: state.inspiration.inspiration,
    contentVersion: state.content.version,
});

const mapDispatcherToProps = (dispatcher) => ({
    addToCart: (product) => new Promise((resolve) => resolve(dispatcher(addToCart(product)))),
});

export default withGATracker(connect(mapStateToProps, mapDispatcherToProps)(BlockFeatureItemsCarousel), 'Featured Items Carousel');
