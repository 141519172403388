// react
import React from 'react';
import { connect } from 'react-redux';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// application
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';

// pages
import ShopPageCart from './Cart';
import SitePageAboutUs from './site/SitePageAboutUs';
import SitePageContactUs from './site/SitePageContactUs';
import SitePageFaq from './site/SitePageFaq';
import SitePageCareers from './site/SitePageCareers';
import SitePageDelivery from './site/SitePageDelivery';
import SitePagePrivacy from './site/SitePagePrivacy';
import SitePageService from './site/SitePageService';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageTerms from './site/SitePageTerms';
import ContentProvider from './ContentProvider';
import Products from './Products';
import ProductPage from './ProductPage';
import InspirationPage from './InspirationPage';
import InspirationList from './Inspiration/InspirationList';

function Layout(props) {
    const {
        // eslint-disable-next-line no-unused-vars
        match: { params },
        headerLayout,
        homeComponent,
        contentVersion,
     } = props;
    
    return (
        <ContentProvider version={params.version}>
            <React.Fragment>
                <Helmet>
                    <title>Festejos Party Rentals and Event Decor</title>
                    <meta name="description" content="Event Equipment and Decor Rentals for weddings, parties, and corporate events" />
                </Helmet>

                <ToastContainer autoClose={5000} hideProgressBar />

                <MobileMenu />

                <div className="site">
                    <header className="site__header d-lg-none">
                        <MobileHeader />
                    </header>

                    <header className="site__header d-lg-block d-none">
                        <Header layout={headerLayout} />
                    </header>

                    <div className="site__body">
                        <Switch>

                            <Redirect exact path="/" to={`${contentVersion}`} /> 
                            {/*
                        // Home
                        */}
                            <Route exact path={`/${contentVersion}`} component={homeComponent} />

                            {/*
                            //Catalog
                            */}
                            <Route
                                exact
                                path={`/${contentVersion}/catalog/:category`}
                                render={(props) => (
                                    <Products {...props} />
                                )}
                            />

                            {/*
                            //Inspirations
                            */}
                            <Route
                                exact
                                path={`/${contentVersion}/inspirations`}
                                render={(props) => (
                                    <InspirationList {...props} />
                                )}
                            />

                            {/*
                            //Product Page
                            */}
                            <Route
                                exact
                                path={`/${contentVersion}/product_page/:category/:title`}
                                render={(props) => (
                                    <ProductPage {...props} />
                                )}
                            />
                            {/*
                            //Inspiration Page
                            */}
                            <Route
                                exact
                                path={`/${contentVersion}/inspiration_page/:title`}
                                render={(props) => (
                                    <InspirationPage {...props} />
                                )}
                            />
                            {/*
                        // Cart
                        */}
                            <Route exact path={`/${contentVersion}/cart`} component={ShopPageCart} />

                            {/*
                        // Site
                        */}
                            <Redirect exact from={`/${contentVersion}/site`} to={`/${contentVersion}/site/about-us`} />
                            <Route exact path={`/${contentVersion}/site/about-us`} component={SitePageAboutUs} />
                            <Route exact path={`/${contentVersion}/site/contact-us`} component={SitePageContactUs} />
                            <Route exact path={`/${contentVersion}/site/faq`} component={SitePageFaq} />
                            <Route exact path={`/${contentVersion}/site/terms`} component={SitePageTerms} />
                            <Route exact path={`/${contentVersion}/site/privacy`} component={SitePagePrivacy} />
                            <Route exact path={`/${contentVersion}/site/careers`} component={SitePageCareers} />
                            <Route exact path={`/${contentVersion}/site/service`} component={SitePageService} />
                            <Route exact path={`/${contentVersion}/site/delivery`} component={SitePageDelivery} />
                            <Route exact path="/site/not-found" component={SitePageNotFound} />

                            {/*
                        // Page Not Found
                        */}
                            <Route component={SitePageNotFound} />
                        </Switch>
                    </div>

                    <footer className="site__footer">
                        <Footer />
                    </footer>
                </div>
            </React.Fragment>
        </ContentProvider>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

const mapStateToProps = (state) => ({ contentVersion: state.content.version });
export default connect(mapStateToProps)(Layout);
