// react
import React from 'react';
import { connect } from 'react-redux';

// third-party
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// application
import NavPanel from './NavPanel';
import Search from './Search';
import { LogoSvg } from '../../svg';
import VirtualAppointmentButton from './VirtualAppointmentButton';


function Header(props) {
    const { layout, contentVersion } = props;
    let bannerSection;

    if (layout === 'default') {
        bannerSection = (
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to={`/${contentVersion}`}><LogoSvg /></Link>
                </div>
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                <div className="site-header__phone">
                    <div className="site-header__phone-title">
                        <FormattedMessage id="header.phoneLabel" defaultMessage="Orlando, Florida" />
                    </div>
                    <div className="site-header__phone-number">
                        <FormattedMessage id="header.phone" defaultMessage="(877) 918-7648" />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <VirtualAppointmentButton />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            {bannerSection}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} />
            </div>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

Header.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({ contentVersion: state.content.version });

export default connect(mapStateToProps)(Header);
