/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { updatePickupDate } from '../../../store/cart';

class PickupDateSelection extends PureComponent {
  filterWeekDays = date => {
      return date.getDay() !== 0 && date.getDay() !== 6;
  }

  static getDerivedStateFromProps = (props) => {
      const { pickupDate: {startDate, endDate }, onPickupDateUpdate } = props;
      if ((startDate !== null) && (endDate !== null)) {
          return;
      }
      let startingDate = new Date();
      startingDate.setHours(0,0,0,0);
      startingDate.setDate(startingDate.getDate() + 1);
      startingDate.setDate(startingDate.getDate() + ((startingDate.getDay() === 6) ? 2 : 0));
      startingDate.setDate((startingDate.getDay() === 0) ? startingDate.getDate() + 1: startingDate.getDate());

      onPickupDateUpdate({ pickupDate: { startDate: startingDate, endDate: startingDate }});
  }

  handleChangeStart = (changedStartDate) => {
      const { onPickupDateUpdate, pickupDate: { endDate } } = this.props;
      let newEndDate = endDate;
      let newStartDate = changedStartDate;
      if (changedStartDate < new Date()) {
          newStartDate = new Date();
      }
      if (newStartDate > newEndDate) {
          newEndDate = newStartDate;
      }

      onPickupDateUpdate({ pickupDate: { startDate: newStartDate, endDate: newEndDate } });
  }

  handleChangeEnd = (changedEndDate) => {
      const { onPickupDateUpdate, pickupDate: { startDate } } = this.props;
      let newEndDate = changedEndDate;
      if (changedEndDate < startDate) {
          newEndDate = startDate;
      }

      onPickupDateUpdate({ pickupDate: { startDate, endDate: newEndDate } });
  }

  render() {
      const { pickupDate: { startDate, endDate } } = this.props;

      const smallDevice = window.matchMedia('(max-width: 440px)').matches;
      return (
          <div className="col-lg-12 col-md-12">
              <div className="form form__form-group">
                      <label>Pickup Date:</label>
                      <div className="date-picker" style={{ marginBotton: 10 }}>
                          <DatePicker
                              selected={new Date(startDate)}
                              startDate={new Date(startDate)}
                              filterDate={this.filterWeekDays}
                              endDate={new Date(endDate)}
                              onChange={this.handleChangeStart}
                              dropDownMode="select"
                              withPortal={smallDevice}
                          />
                      </div>
                      <label style={{ marginTop: 15 }}>Return Date:</label>
                      <div className="date-picker" style={{ marginBotton: 10 }}>
                          <DatePicker
                              selected={new Date(endDate)}
                              startDate={new Date(startDate)}
                              filterDate={this.filterWeekDays}
                              endDate={new Date(endDate)}
                              onChange={this.handleChangeEnd}
                              dropDownMode="select"
                              withPortal={smallDevice}
                          />
                      </div>
              </div>
          </div>
      );
  }
}

PickupDateSelection.propTypes = {
    onPickupDateUpdate: PropTypes.func.isRequired,
    pickupDate: PropTypes.objectOf(Object),
};

PickupDateSelection.defaultProps = {
    pickupDate: {
        startDate: null,
        endDate: null,
    }
}

const mapStateToProps = (state) => ({ pickupDate: state.cart.pickupDate });

const mapDispatcherToProps = (dispatcher) => (
    {
        onPickupDateUpdate: ((date) => dispatcher(updatePickupDate(date))),
    });

export default connect(mapStateToProps, mapDispatcherToProps)(PickupDateSelection);
