import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

export default function withTracker(WrappedComponent, pageType, options = {}) {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      const search = this.props.location.search;
      const qrtrack = search.match(/qrtrack=\w+/);
      if(!qrtrack){
        trackPage(page);
        return;
      }

      trackPage(`${page}?${qrtrack[0]}`);
      //removes qrtrack parameter from the current URL to prevent user from bookmarking the URL with the qrtrack parameter
      this.props.history.push({
        pathname: page,
        search: search.replace(/qrtrack=\w+&?/, ''),
      });
    }

    componentDidUpdate(prevProps) {
      const currentPage = prevProps.location.pathname;
      const nextPage = this.props.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          pageGAEvent={(action, label) => ReactGA.event({ category: pageType, action: action, label: label })}
          pageLinkTo={ (site, item) => {
              ReactGA.event({ category: 'Page Link', action:`Click ${site}`, label: item});
            }
        }
        />
      );
    }
  };

  return withRouter(HOC);
}