// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';

import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

import DeliveryCalculator from './DeliveryCalculator';

// application
import InputNumber from '../../shared/InputNumber';
import PageHeader from '../../shared/PageHeader';
import
   {removeFromCart,
    updateCartItem,
    resetCart,
    updateDeliveryChoice,
    resetPickupDate,
 } from '../../../store/cart';
import { Cross12Svg } from '../../../svg';
import UserInfoVerification from '../../UserInfoVerification';

import withGATracker from '../../GATracking/withGATracker';
//import CalculateDeliveryCollapseButton from './CalculateDeliveryCollapseButton';
import PickupDateSelection from './PickupDateSelection';

const { REACT_APP_FESTEJOSWEB_BACKEND_URL } = process.env

const verifyResponse = (r) => {
    if (r.ok) {
        return r;
      }
    if (r.status === 400) {
      throw Error('Invalid Code')
    }
  };

class ShopPageCart extends React.Component {
    constructor() {
        super();
        this.state = {
            missingInformationError: null,
        };

        this.onSendQuote = this.onSendQuote.bind(this);
    }

    async onSendQuote(userData) {
        const {
          verificationCode,
          email,
          fullName,
          phoneNumber
        } = userData;
    
        const {
            cart,
            contentVersion,
            googleReCaptchaProps,
            pageGAEvent,
        } = this.props;
        const modifiedDatesCart = {
          ...cart,
          pickupDate:{
              startDate: Math.trunc(new Date(cart.pickupDate.startDate).getTime() / 1000),
              endDate: Math.trunc(new Date(cart.pickupDate.endDate).getTime() / 1000),
          },
          deliveryInfo: {
            ...cart.deliveryInfo,
            startDate: Math.trunc(new Date(cart.deliveryInfo.startDate).getTime() / 1000),
            endDate: Math.trunc(new Date(cart.deliveryInfo.endDate).getTime() / 1000),
          },
        };
        const dataToSubmit = {
          email,
          code: verificationCode,
          quote: modifiedDatesCart,
          client_name: fullName,
          client_phone: phoneNumber,
          content_version: contentVersion,
        };
        
        const token = await googleReCaptchaProps.executeRecaptcha('send_online_quote');
        
        pageGAEvent('Send Quote', 'Send Quote');
        return fetch(`${REACT_APP_FESTEJOSWEB_BACKEND_URL}onlinequote`, {
          method: 'POST',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataToSubmit),
        }).then(response => verifyResponse(response))
          .then(data => data);
      }

    verifyBeforeSending = () => {
        const { deliveryCost, isDeliverySelected } = this.props;
        if (isDeliverySelected == null) {
            this.setState({missingInformationError: 'Choose "FREE Store Pickup" or "Delivery"'});
            this.forceUpdate();
            return false;
        }

        if (isDeliverySelected && (deliveryCost === 0)) {
            this.setState({missingInformationError: "Please calculate delivery"});
            this.forceUpdate();
            return false;
        }

        return true;
    }

    displayDeliveryChoiceError = () => {
        const { missingInformationError } = this.state;
        return (missingInformationError)
                               ? <span className="form__form-group-label" style={{ color: 'red', fontSize: 14 }}>{missingInformationError}</span>
                               : null
    }

    onEmptyCart = () => {
        const { emptyCart, pageGAEvent } = this.props;
        emptyCart();
        pageGAEvent('Send Quote - User Confirmed', 'Send Quote - User Confirmed');
    }

    onCartRemoveItem = (item) => {
        const { cartRemoveItem, pageGAEvent } = this.props;
        cartRemoveItem(item);
        pageGAEvent('Remove from Cart', `${item.category}/${item.title}`);
    }

    onPickupSelected = (e) => {
        const { setStorePickupAsChoice } = this.props;
        setStorePickupAsChoice();
        this.setState({ missingInformationError: null, deliverySelected: false});
    }

    onDeliverySelected = (e) => {
        const { setDeliveryAsChoice, clearExtraRentalDays } = this.props;
        setDeliveryAsChoice();
        this.setState( { missingInformationError: null, deliverySelected: true});
        clearExtraRentalDays();
    }
    
    renderItems = () => {
        const {
            itemsInCart,
            onCartItemUpdate,
            contentVersion,
        } = this.props;

        return itemsInCart.map((item) => {
            let options;

            const image = <Link to={`/${contentVersion}/product_page/${item.category}/${item.title}`}><img src={item.img} alt="" /></Link>;
            const title = `${item.title}, ${item.selectedColor}`;

            return (
                <tr key={`${item.title}${item.selectedColor}${item.category}`} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">
                        {image}
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link to={`/${contentVersion}/product_page/${item.category}/${item.title}`} className="cart-table__product-name">
                            {title}
                        </Link>
                        {options}
                    </td>
                    <td className="cart-table__column cart-table__column--price" data-title="Price">
                        {`$${item.price.toFixed(2) || 0}`}
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                        <InputNumber
                            name={title}
                            value={item.quantity}
                            min={1}
                            size="sm"
                            onChange={(e) => {
                                // eslint-disable-next-line max-len
                                const updatedQuantity = Number(e.currentTarget.value);
                                // eslint-disable-next-line max-len
                                const updatedTotal = (updatedQuantity * Number(item.price));
                                return onCartItemUpdate({
                                    item,
                                    quantity: updatedQuantity,
                                    total: updatedTotal,
                                });
                            }}
                        />
                    </td>
                    <td className="cart-table__column cart-table__column--total" data-title="Total">
                        {`$${(item.total) ? item.total.toFixed(2) : 0}`}
                    </td>
                    <td className="cart-table__column cart-table__column--remove">
                    <button
                      type="button"
                      onClick={() => this.onCartRemoveItem(item)}
                      className="btn btn-light btn-sm btn-svg-icon"
                    >
                      <Cross12Svg />
                    </button>
                    </td>
                </tr>
            );
        });
    };

    renderTotals = () => {
        const {
            subTotal,
            itemsInCart,
            deliveryCost,
            totalTax,
            extraRentalDays,
        } = this.props;
        const { isDeliverySelected } = this.props;


        if (itemsInCart.length <= 0) {
            return null;
        }

        let delivery = 0;

        if (deliveryCost) {
            delivery = deliveryCost.total_delivery_cost + deliveryCost.total_pickup_cost;
        }
        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        <td>{`$${subTotal.toFixed(2) || 0}`}</td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body">
                    <tr key={0}>
                        <td colspan={2}>
                           {
                               this.displayDeliveryChoiceError()
                           }
                        </td>
                    </tr>
                    <tr key={1}>
                        <td colspan={1} width='60%'>
                           <input type="radio" id="pickup" name="requiredDelivery" onChange={this.onPickupSelected} checked={(isDeliverySelected != null) && !isDeliverySelected} />
                           <label htmlFor="pickup" style={{ marginLeft: 3 }} >FREE Store Pickup</label>
                        </td>
                        <td colspan={1} width='40%'>
                           <input type="radio" id="delivery" name="requiredDelivery" onChange={this.onDeliverySelected} checked={(isDeliverySelected != null) && isDeliverySelected} />
                           <label htmlFor="delivery" style={{ marginLeft: 3 }}>Delivery</label>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr key={2}>
                        <td colSpan={2}>
                        <Collapse isOpen={(isDeliverySelected != null)} className="colapse__content" >
                            {
                                isDeliverySelected ? <DeliveryCalculator /> : <PickupDateSelection /> 
                            }
                        </Collapse>
                        </td>
                    </tr>
                    <tr key={3}>
                        <th>
                          Extra Day(s) Rental Charge
                        </th>
                        <td>
                            {`$${(extraRentalDays) ? ((extraRentalDays * subTotal).toFixed(2) || 0): 0}`}
                        </td>
                    </tr>
                    <tr key={4}>
                        <th>
                          Delivery Charge
                        </th>
                        <td>
                            {`$${delivery.toFixed(2) || 0}`}
                        </td>
                    </tr>
                    <tr key={5}>
                        <th>Tax</th>
                        <td>
                            {`$${totalTax.toFixed(2) || 0}`}
                        </td>
                    </tr>
                    { /* <tr key={6}>
                        <td colspan={2}>
                          <CalculateDeliveryCollapseButton>
                            <DeliveryCalculator />
                          </CalculateDeliveryCollapseButton>
                        </td>
                    </tr> */
                    }
                </tbody>
            </React.Fragment>
        );
    };

    renderCart = () => {
        const {
            subTotal,
            deliveryCost,
            extraRentalDays,
            totalTax,
        } = this.props;
        let delivery = 0;
        if (deliveryCost) {
            delivery = deliveryCost.total_delivery_cost + deliveryCost.total_pickup_cost;
        }

        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">Image</th>
                                <th className="cart-table__column cart-table__column--product">Product</th>
                                <th className="cart-table__column cart-table__column--price">Price</th>
                                <th className="cart-table__column cart-table__column--quantity">Quantity</th>
                                <th className="cart-table__column cart-table__column--total">Total</th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {this.renderItems()}
                        </tbody>
                    </table>
                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-5 ">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Cart Totals</h3>
                                    <table className="cart__totals">
                                        {this.renderTotals()}
                                        <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th>Total</th>
                                                <td>{`$${(subTotal + delivery + totalTax + (extraRentalDays * subTotal)).toFixed(2) || 0}`}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    {
                                        this.displayDeliveryChoiceError()
                                    }
                                    <UserInfoVerification
                                      onSubmit={this.onSendQuote}
                                      verifyBeforeSending={this.verifyBeforeSending}
                                      succesMessage="We sent you an email with your quote. A sales representative will be contacting you to finalize your order"
                                      submitButtonLabel="Send Rental Quote"
                                      eventually={this.onEmptyCart}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { itemsInCart, contentVersion } = this.props;
        let content;

        if (itemsInCart.length) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message">Your rental reservation cart is empty!</div>
                            <div className="block-empty__actions">
                                <Link to={`/${contentVersion}`} className="btn btn-primary btn-sm">Continue Shopping</Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Festejos Rentals Reservation Cart`}</title>
                </Helmet>

                <PageHeader
                  header="Rental Reservation Cart"
                  subheader={
                      (itemsInCart.length)
                      ? "This is your rental reservation quote. Proceed to send us your quote, and a sales representative will contact you to finalize your order."
                      : null
                  }
                />
                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    itemsInCart: state.cart.itemsInCart,
    subTotal: state.cart.subTotal,
    totalTax: state.cart.totalTax,
    deliveryCost: state.cart.deliveryCost,
    extraRentalDays: state.cart.extraRentalDays,
    isDeliverySelected: state.cart.isDeliverySelected,
    contentVersion: state.content.version,
});

const mapDispatchToProps = (dispatcher) => ({
    cartRemoveItem: (data) => new Promise((resolve) => resolve(dispatcher(removeFromCart(data)))),
    onCartItemUpdate: (data) => dispatcher(updateCartItem(data)),
    emptyCart: () => dispatcher(resetCart()),
    setDeliveryAsChoice: () => dispatcher(updateDeliveryChoice({isDeliverySelected: true})),
    setStorePickupAsChoice: () => dispatcher(updateDeliveryChoice({isDeliverySelected: false})),
    clearExtraRentalDays: () => dispatcher(resetPickupDate()),
});

export default withGATracker(withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(ShopPageCart)), 'Rental Reservation Cart');
