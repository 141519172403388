export const LOAD_PRODUCT = 'LOAD_PRODUCT';
export const SET_CURRENT_IMAGE_PREVIEW = 'SET_CURRENT_IMAGE_PREVIEW';
export const ADD_TO_BREADCRUMBS = 'ADD_TO_BREADCRUMBS';
export const RESET_BREADCRUMBS = 'RESET_BREADCRUMBS';

export function loadProductAction(product) {
    return {
        type: LOAD_PRODUCT,
        data: product,
    };
}

export function setCurrentImagePreview(image) {
    return {
        type: SET_CURRENT_IMAGE_PREVIEW,
        data: image,
    };
}

export function addToBreadCrumbs(product) {
    return {
        type: ADD_TO_BREADCRUMBS,
        data: product,
    };
}

export function resetBreadCrumbs() {
    return {
        type: RESET_BREADCRUMBS,
    };
}
