/* eslint-disable linebreak-style */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';
import { ImageNotification } from './Notification';

function ImageNotifications(props) {
    const {
        image,
        title,
        message,
    } = props;
    return (
        <ImageNotification
            title={title}
            img={image}
            message={message}
        />
    );
}

ImageNotifications.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};

export default ImageNotifications;
