/* eslint-disable linebreak-style */
import React from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InspirationImage from './InspirationImage';
import PageHeader from '../shared/PageHeader';

import withGATracker from '../GATracking/withGATracker';

const InspirationList = ({ inspirations }) => (
    <Container>
        <Row>
            <PageHeader header="Inspirations" />
        </Row>
        <Row>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexFlow: 'row wrap',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                {
                    inspirations.map((inspiration) => (
                        <div style={{ margin: 5 }}>
                            <InspirationImage
                                image={inspiration.image}
                                title={inspiration.title}
                                featuredItems={inspiration.items}
                            />
                        </div>
                    ))
                }
            </div>
        </Row>
    </Container>
);

const mapStateToProps = (state) => ({ inspirations: state.content.inspiration });

export default withGATracker(connect(mapStateToProps)(withRouter(InspirationList)), 'Inspirations Page');
