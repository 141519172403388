// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// blocks
import BlockSlideShow from '../blocks/BlockSlideShow';
import BlockInspirationsCarousel from '../blocks/BlockInspirationsCarousel';
import BlockNewArrivalsCarousel from '../blocks/BlockNewArrivalsCarousel';

import withGATracker from '../GATracking/withGATracker';

function HomePageOne() {
    return (
        <React.Fragment>
            <Helmet>
              <title>Festejos Party Rentals and Event Decor</title>
              <meta name="description" content="Festejos Party Rentals and Event Decor is an event rental company, renting event equipment and decor items servicing the Central Florida area"/>
            </Helmet>

            <BlockSlideShow />

            <BlockInspirationsCarousel layout="grid-4" />

            <BlockNewArrivalsCarousel />

        </React.Fragment>
    );
}

export default withGATracker(HomePageOne, 'Landing Page');
