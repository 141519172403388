/* eslint-disable linebreak-style */
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

const Sale = `${process.env.PUBLIC_URL}/images/sale_lable.png`;
const New = `${process.env.PUBLIC_URL}/images/new_lable.png`;

class ProductItems extends PureComponent {
    render() {
        const { items, contentVersion } = this.props;
        return (
            <div className="catalog-items__wrap">
                <div className="catalog-items">
                    {items.map((item, i) => (
                        <div className="catalog-item" key={i}>
                            <Link className="catalog-item__link" to={`/${contentVersion}/product_page/${item.category}/${item.title}`}>
                                {item.isNew ? <img className="catalog-item__label" src={New} alt="new" /> : ''}
                                {item.sale ? <img className="catalog-item__label" src={Sale} alt="sale" /> : ''}
                                <div className="catalog-item__img-wrap">
                                    <img className="catalog-item__img" src={item.image} alt="catalog-item-img" />
                                </div>
                                <div className="catalog-item__info">
                                    <h4 className="catalog-item__title">{item.title}</h4>
                                    <p className="catalog-item__description">{item.short_description}</p>
                                    <h1 className="catalog-item__price">
$
                                        {item.price / 100}
                                    </h1>
                                    {item.sale ? (
                                        <p className="catalog-item__old-price">
$
                                            {item.oldPrice}
                                        </p>
                                    ) : ''}
                                    {item.colors.map((c, index) => (
                                        <span
                                            key={index}
                                            className="catalog-item__color"
                                            style={{ background: c.code }}
                                        />
                                    ))}
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

ProductItems.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        category: PropTypes.string,
        image: PropTypes.string,
        title: PropTypes.string,
        price: PropTypes.string,
        short_description: PropTypes.string,
        colors: PropTypes.arrayOf(PropTypes.string),
        isNew: PropTypes.bool,
    })),
};

ProductItems.defaultProps = {
    items: [],
};

const mapStateToProps = (state) => ({ contentVersion: state.content.version });

export default connect(mapStateToProps)(withRouter(ProductItems));
