import { PureComponent } from 'react';
import ReactGA from 'react-ga';

const { REACT_APP_GA_TRACKINGID } = process.env;

const DEFAULT_CONFIG = {
    trackingId: REACT_APP_GA_TRACKINGID,
    debug: false,
    gaOptions: {
      cookieDomain: 'none'
    }
  };

class GATrackerProvider extends PureComponent {
    constructor() {
        super();
        ReactGA.initialize(DEFAULT_CONFIG);
    }

    render() {
        const { children } = this.props;
        return  children;
    }

} 

export default GATrackerProvider;