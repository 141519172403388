import React from 'react';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

import UserInfoForm from './UserInfoForm';
import EmailQuoteFormModal from './EmailQuoteFormModal';

import withGATracker from '../../GATracking/withGATracker';

const { REACT_APP_FESTEJOSWEB_BACKEND_URL } = process.env;

const verifyResponse = (r) => {
    if (r.ok) {
        return r;
      }
    if (r.status === 400) {
      throw Error('Invalid Code')
    }
  };

class UserInfoFormCard extends React.Component {
    constructor() {
        super();
        this.state = {
            isModalOpen: false,
            errorMessage: '',
            email: '',
            phoneNumber: '',
            fullName: '',
            loading: false,
        };
        this.onVerificationRequest = this.onVerificationRequest.bind(this);
    }

    toggleModal = () => {
        const { isModalOpen } = this.state;
        this.setState({ isModalOpen: !isModalOpen });
    };

    getUserInfo = () => {
        const { fullName, email, phoneNumber } = this.state;
        return { fullName, email, phoneNumber };
    }

    setUserInfo = (email, name, phone) => {
        this.setState({ fullName: name, email: email, phoneNumber: phone});
    }
    
    async onVerificationRequest(data) {
        const { verifyBeforeSending } = this.props;
        
        if (!verifyBeforeSending()){
          return;
        }

        const { email } = data;
        const { googleReCaptchaProps, pageGAEvent } = this.props;
    
       const token = await googleReCaptchaProps.executeRecaptcha('send_email_verification');

       this.setState({ loading: true });

        pageGAEvent('Send Email Verification Code', 'Send Email Verification Code');

        return fetch(`${REACT_APP_FESTEJOSWEB_BACKEND_URL}sendemailverification`, {
          method: 'POST',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        }).then(dta => verifyResponse(dta))
          .then(() => this.setState({
              isModalOpen: true,
              email: data.email,
              phoneNumber: data.phoneNumber,
              fullName: data.fullName,
              loading: false,
            }))
          .catch(error => this.setState({ errorMessage: error.message, loading: false }));
    }

      render() {
          const {
              isModalOpen,
              errorMessage,
              loading,
            } = this.state;
          const {
              onSubmit,
              succesMessage,
              submitButtonLabel,
              eventually,
            } = this.props;
          return (
              <div className="col-lg-12 col-md-12" style={{ alignSelf: 'center' }}>
                  <span style={{ color: 'red' }}>{errorMessage}</span>
                  <UserInfoForm
                    onSubmit={this.onVerificationRequest}
                    submitButtonLabel={submitButtonLabel}
                    loading={loading}
                  />
                  <EmailQuoteFormModal
                    isOpen={isModalOpen}
                    toggle={this.toggleModal}
                    onSubmit={onSubmit}
                    succesMessage={succesMessage}
                    submitButtonLabel={submitButtonLabel}
                    resubmitVerificationRequest={this.onVerificationRequest}
                    eventually={eventually}
                  />
            </div>
          );
      }
}

export default withGATracker(withGoogleReCaptcha(UserInfoFormCard), 'User Info Form');