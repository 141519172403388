const cityOptions = [
    'Alachua FL',
    'Allandale FL',
    'Altamonte Springs FL',
    'Altoona FL',
    'Alturas FL',
    'Anna Maria FL',
    'Anthony FL',
    'Apollo Beach FL',
    'Apopka FL',
    'Arcadia FL',
    'Archer FL',
    'Aripeka FL',
    'Astatula FL',
    'Astor FL',
    'Auburndale FL',
    'Avon Park FL',
    'Babson Park FL',
    'Balm FL',
    'Barberville FL',
    'Bartow FL',
    'Bay Pines FL',
    'Bayonet Point FL',
    'Bell FL',
    'Belle Isle FL',
    'Belleair Beach FL',
    'Belleair Bluffs FL',
    'Belleair FL',
    'Belleview FL',
    'Beverly Hills FL',
    'Bostwick FL',
    'Bowling Green FL',
    'Bradenton Beach FL',
    'Bradenton FL',
    'Bradley FL',
    'Brandon FL',
    'Bronson FL',
    'Brooksville FL',
    'Bunnell FL',
    'Bushnell FL',
    'Candler FL',
    'Cape Canaveral FL',
    'Cassadaga FL',
    'Casselberry FL',
    'Cedar Key FL',
    'Celebration FL',
    'Center Hill FL',
    'Championsgate FL',
    'Chiefland FL',
    'Christmas FL',
    'Chuluota FL',
    'Citra FL',
    'Citrus Springs FL',
    'Clarcona FL',
    'Clearwater FL',
    'Clermont FL',
    'Cocoa Beach FL',
    'Cocoa FL',
    'Coleman FL',
    'Cortez FL',
    'Crescent City FL',
    'Cross City FL',
    'Crystal Beach FL',
    'Crystal River FL',
    'Crystal Springs FL',
    'Dade City FL',
    'Davenport FL',
    'Daytona Beach FL',
    'Daytona Beach Shores FL',
    'De Leon Springs FL',
    'Debary FL',
    'Deland FL',
    'Deltona FL',
    'Dover FL',
    'Dundee FL',
    'Dunedin FL',
    'Dunnellon FL',
    'Durant FL',
    'Eagle Lake FL',
    'Earleton FL',
    'East Palatka FL',
    'Eastlake Weir FL',
    'Eaton Park FL',
    'Edgewater FL',
    'Elfers FL',
    'Elkton FL',
    'Ellenton FL',
    'Englewood FL',
    'Eustis FL',
    'Evinston FL',
    'Fairfield FL',
    'Falkenburg FL',
    'Fanning Springs FL',
    'Fellsmere FL',
    'Fern Park FL',
    'Ferndale FL',
    'Flagler Beach FL',
    'Florahome FL',
    'Floral City FL',
    'Florence Villa FL',
    'Fort McCoy FL',
    'Fort Meade FL',
    'Fort Ogden FL',
    'Fort Pierce FL',
    'Frostproof FL',
    'Fruitland Park FL',
    'Gainesville FL',
    'Geneva FL',
    'Georgetown FL',
    'Gibsonton FL',
    'Goldenrod FL',
    'Gotha FL',
    'Grand Island FL',
    'Grandin FL',
    'Grant FL',
    'Groveland FL',
    'Gulf Hammock FL',
    'Gulfport FL',
    'Haines City FL',
    'Hampton FL',
    'Hastings FL',
    'Hawthorne FL',
    'Hernando FL',
    'High Springs FL',
    'Highland City FL',
    'Hobe Sound FL',
    'Holder FL',
    'Holiday FL',
    'Hollister FL',
    'Holly Hill FL',
    'Holmes Beach FL',
    'Home Shopping FL',
    'Homeland FL',
    'Homosassa FL',
    'Homosassa Springs FL',
    'Howey in the Hills FL',
    'Hudson FL',
    'Indialantic FL',
    'Indian Harbour Beach FL',
    'Indian Lake Estates FL',
    'Indian Rocks Beach FL',
    'Indian Shores FL',
    'Indiantown FL',
    'Inglis FL',
    'Intercession City FL',
    'Interlachen FL',
    'Inverness FL',
    'Island Grove FL',
    'Istachatta FL',
    'Jensen Beach FL',
    'Kathleen FL',
    'Kenansville FL',
    'Kennedy Space Center FL',
    'Keystone Heights FL',
    'Killarney FL',
    'Kissimmee FL',
    'La Crosse FL',
    'Lacoochee FL',
    'Lady Lake FL',
    'Lake Alfred FL',
    'Lake Buena Vista FL',
    'Lake Como FL',
    'Lake Geneva FL',
    'Lake Hamilton FL',
    'Lake Helen FL',
    'Lake Mary FL',
    'Lake Monroe FL',
    'Lake Panasoffkee FL',
    'Lake Placid FL',
    'Lake Wales FL',
    'Lakeland FL',
    'Lakewood FL',
    'Lakewood Ranch FL',
    'Land O Lakes FL',
    'Largo FL',
    'Laurel FL',
    'Lecanto FL',
    'Leesburg FL',
    'Lithia FL',
    'Lochloosa FL',
    'Longboat Key FL',
    'Longwood FL',
    'Lorida FL',
    'Loughman FL',
    'Lowell FL',
    'Lutz FL',
    'Madeira Beach FL',
    'Maitland FL',
    'Malabar FL',
    'Manasota FL',
    'Mango FL',
    'Mascotte FL',
    'McIntosh FL',
    'Melbourne Beach FL',
    'Melbourne FL',
    'Melrose FL',
    'Merritt Island FL',
    'Micanopy FL',
    'Mid Florida FL',
    'Mims FL',
    'Minneola FL',
    'Montverde FL',
    'Morriston FL',
    'Mount Dora FL',
    'Mulberry FL',
    'Murdock FL',
    'Myakka City FL',
    'Nalcrest FL',
    'New Port Richey FL',
    'New Smyrna Beach FL',
    'New Tampa FL',
    'Newberry FL',
    'Nichols FL',
    'Nobleton FL',
    'Nocatee FL',
    'Nokomis FL',
    'North Port FL',
    'North Redington Beach FL',
    'Oak Hill FL',
    'Oakland FL',
    'Ocala FL',
    'Ocklawaha FL',
    'Ocoee FL',
    'Odessa FL',
    'Okahumpka FL',
    'Okeechobee FL',
    'Old Town FL',
    'Oldsmar FL',
    'Ona FL',
    'Oneco FL',
    'Orange City FL',
    'Orange Lake FL',
    'Orange Springs FL',
    'Orlando FL',
    'Orlo Vista FL',
    'Ormond Beach FL',
    'Osprey FL',
    'Osteen FL',
    'Otter Creek FL',
    'Oviedo FL',
    'Oxford FL',
    'Ozona FL',
    'Paisley FL',
    'Palatka FL',
    'Palm Bay FL',
    'Palm City FL',
    'Palm Coast FL',
    'Palm Harbor FL',
    'Palmetto FL',
    'Parrish FL',
    'Patrick AFB FL',
    'Pierson FL',
    'Pine Island FL',
    'Pinellas Park FL',
    'Plant City FL',
    'Plymouth FL',
    'Poinciana FL',
    'Polk City FL',
    'Pomona Park FL',
    'Ponce Inlet FL',
    'Port Charlotte FL',
    'Port Orange FL',
    'Port Richey FL',
    'Port Saint Lucie FL',
    'Port Salerno FL',
    'Putnam Hall FL',
    'Reddick FL',
    'Redington Beach FL',
    'Redington Shores FL',
    'Riverview FL',
    'Rockledge FL',
    'Roseland FL',
    'Royal FL',
    'Ruskin FL',
    'Safety Harbor FL',
    'Saint Augustine FL',
    'Saint Cloud FL',
    'Saint Leo FL',
    'Saint Lucie West FL',
    'Saint Pete Beach FL',
    'Saint Petersburg FL',
    'San Antonio FL',
    'San Mateo FL',
    'Sanford FL',
    'Sarasota FL',
    'Satellite Beach FL',
    'Satsuma FL',
    'Scottsmoor FL',
    'Sebastian FL',
    'Sebring FL',
    'Seffner FL',
    'Seminole FL',
    'Seville FL',
    'Sharpes FL',
    'Silver Springs FL',
    'Sorrento FL',
    'South Daytona FL',
    'Sparr FL',
    'Spring Hill FL',
    'St. Augustine FL',
    'St. Cloud FL',
    'St. Petersburg FL',
    'Stuart FL',
    'Summerfield FL',
    'Sumterville FL',
    'Sun City Center FL',
    'Sun City FL',
    'Sydney FL',
    'Tallevast FL',
    'Tampa FL',
    'Tangerine FL',
    'Tarpon Springs FL',
    'Tavares FL',
    'Temple Terrace FL',
    'Terra Ceia FL',
    'The Villages FL',
    'Thonotosassa FL',
    'Titusville FL',
    'Town n Country FL',
    'Treasure Island FL',
    'Trenton FL',
    'Trilby FL',
    'Trinity FL',
    'Umatilla FL',
    'Valrico FL',
    'Venice FL',
    'Venus FL',
    'Vero Beach FL',
    'Wabasso FL',
    'Waldo FL',
    'Wauchula FL',
    'Waverly FL',
    'Webster FL',
    'Weeki Wachee FL',
    'Weirsdale FL',
    'Welaka FL',
    'Wesley Chapel FL',
    'West Melbourne FL',
    'Wildwood FL',
    'Williston FL',
    'Wimauma FL',
    'Windermere FL',
    'Winter Beach FL',
    'Winter Garden FL',
    'Winter Haven FL',
    'Winter Park FL',
    'Winter Springs FL',
    'Yalaha FL',
    'Yankeetown FL',
    'Zellwood FL',
    'Zephyrhills FL',
    'Zolfo Springs FL',
];

export default cityOptions;